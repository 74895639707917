import React from "react";
import { Modal, Button } from "react-bootstrap";
import { MdDelete, MdEdit } from "react-icons/md";

const InstructionModal = ({ showInfo, setShowInfo }) => {
  return (
    <Modal show={showInfo} size="lg" onHide={() => setShowInfo(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Ohjeet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="pl-30px">Käytä hakukenttää teosten hakuun</p>
        <hr/>
        <p>
          <MdEdit size={30} />
          Kuvakkeesta näet/muokkaat teoksen tarkempia tietoja.
        </p>
        <hr/>
        <p>
          <MdDelete size={30} />
          Kuvakkeesta poistat teoksen
        </p>
        <hr/>
        <p className="pl-30px">
          Voit suodattaa teoksia valitsemalla taitelijan nimen tai pääluokan
        </p>
        <hr/>
        <p className="pl-30px">Teoksen kuvaa klikkaamalla siirryt kuvankatselutilaan.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowInfo(false)}>
          Sulje
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InstructionModal;
