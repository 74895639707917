import React from 'react';

import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  CardMedia,
  Typography,
} from '@mui/material';

import { updateProduct } from '../../../../store/actions/productActions';
import { defaultImage } from '../../../../utils/HelperFunction';

const ProductDetails = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const locations = useSelector((state) => state.product.locations);
  const product = useSelector((state) => state.product);
  const handleSubmit = (values) => {
    setIsSubmitting(true);
    dispatch(updateProduct(values))
      .then(() => {
        setIsSubmitting(false);
        enqueueSnackbar(`${product.art_id} päivitetty`, {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(`${product.art_id} päivitys epäonnistui`, {
          variant: "error",
        });
        setIsSubmitting(false);
      });
  };

  const sortedLocations = locations.sort((a, b) => {
    return new Date(b.created) - new Date(a.created);
  });

  return (
    <>
      <Typography variant="h4" component="h1">
        Perustiedot
      </Typography>

      <Row className="my-3">
        <Col lg={5}>
          <CardMedia
            component="img"
            alt={`${product.name} image`}
            image={defaultImage(product.documents)}
          />
        </Col>
        <Col lg={7}>
          <Typography variant="h6" component="h2">
            <Row>
              <Col xs={4}>TeosID:</Col>
              <Col xs={8}>{product.art_id}</Col>
            </Row>
          </Typography>
          <Typography variant="h6" component="h2">
            <Row>
              <Col xs={4}>Taiteilija:</Col>
              <Col xs={8}>{product.artist}</Col>
            </Row>
          </Typography>
          <Typography variant="h6" component="h2">
            <Row>
              <Col xs={4}>Teoksen nimi: </Col>
              <Col xs={8}>{product.title}</Col>
            </Row>
          </Typography>
          <Typography variant="h6" component="h2">
            <Row>
              <Col xs={4}>Ajoitus:</Col>
              <Col xs={8}>{product.year}</Col>
            </Row>
          </Typography>
          <Typography variant="h6" component="h2">
            <Row>
              <Col xs={4}>Pääluokka:</Col>
              <Col xs={8}>{product.mainclass}</Col>
            </Row>
          </Typography>
          <Typography variant="h6" component="h2">
            <Row>
              <Col xs={4}>Sijainti:</Col>
              <Col xs={8}>
                {sortedLocations.length > 0
                  ? sortedLocations[0].location
                  : "Ei sijaintia"}
              </Col>
            </Row>
          </Typography>
        </Col>
      </Row>

      <Formik
        enableReinitialize
        initialValues={{
          id: product.id,
          invnumber1: product.invnumber1,
          invnumber2: product.invnumber2,
          purchase_date: product.purchase_date,
          year: product.year,
          value: product.value,
          purchase_price: product.purchase_price,
          art_description: product.art_description,
          subclass: product.subclass,
          technique: product.technique,
          materials: product.materials,
          signature: product.signature,
          signature_info: product.signature_info,
          draft_info: product.draft_info,
          height: product.height,
          depth: product.depth,
          width: product.width,
          numofparts: product.numofparts,
          partsdesc: product.partsdesc,
        }}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group className="mb-3" controlId="validationArtDescription">
                <Form.Label>Tuote Kuvaus</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="art_description"
                  value={values.art_description}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationInvNro1">
                <Form.Label>Inventaario 1</Form.Label>
                <Form.Control
                  type="text"
                  name="invnumber1"
                  value={values.invnumber1}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationInvNro2">
                <Form.Label>Inventaario 2</Form.Label>
                <Form.Control
                  type="text"
                  name="invnumber2"
                  value={values.invnumber2}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationPurchaseDate">
                <Form.Label>Hankinta päivämäärä</Form.Label>
                <Form.Control
                  type="date"
                  name="purchase_date"
                  value={values.purchase_date}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationPurchasePrice">
                <Form.Label>Hankintahinta</Form.Label>
                <Form.Control
                  type="number"
                  name="purchase_price"
                  value={values.purchase_price}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationValue">
                <Form.Label>Vakuutusarvo</Form.Label>
                <Form.Control
                  type="number"
                  name="value"
                  value={values.value}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group className="mb-3" controlId="validationYear">
                <Form.Label>Ajoitus</Form.Label>
                <Form.Control
                  type="text"
                  name="year"
                  value={values.year}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationSubClass">
                <Form.Label>Alaluokka</Form.Label>
                <Form.Control
                  type="text"
                  name="subclass"
                  value={values.subclass}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationDraftInfo">
                <Form.Label>Vedostiedot</Form.Label>
                <Form.Control
                  type="text"
                  name="draft_info"
                  value={values.draft_info}
                  onChange={handleChange}
                  isInvalid={!!errors.draft_info}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationTechnique">
                <Form.Label>Tekniikka</Form.Label>
                <Form.Control
                  type="text"
                  name="technique"
                  value={values.technique}
                  onChange={handleChange}
                  isInvalid={!!errors.technique}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationMaterials">
                <Form.Label>Materiaalit</Form.Label>
                <Form.Control
                  type="text"
                  name="materials"
                  value={values.materials}
                  onChange={handleChange}
                  isInvalid={!!errors.materials}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Typography variant="h6" component="h2">
                Teoksen mitat
              </Typography>
              <Form.Group as={Col} md="4" controlId="validationHeight">
                <Form.Label>Korkeus</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="height"
                    value={values.height}
                    onChange={handleChange}
                    isInvalid={!!errors.height}
                  />
                  <InputGroup.Text id="inputGroupPrepend">cm</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationWidth">
                <Form.Label>Leveys</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="width"
                    value={values.width}
                    onChange={handleChange}
                    isInvalid={!!errors.width}
                  />
                  <InputGroup.Text id="inputGroupPrepend">cm</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationDepth">
                <Form.Label>Syvyys</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="number"
                    name="depth"
                    value={values.depth}
                    onChange={handleChange}
                    isInvalid={!!errors.depth}
                  />
                  <InputGroup.Text id="inputGroupPrepend">cm</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationSignature">
                <Form.Label>Signeeraus</Form.Label>
                <Form.Control
                  type="text"
                  name="signature"
                  value={values.signature}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationSignatureInfo">
                <Form.Label>Signeeraustiedot</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="signature_info"
                  value={values.signature_info}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationNumOfParts">
                <Form.Label>Osien lukumäärä</Form.Label>
                <Form.Control
                  type="number"
                  name="numofparts"
                  value={values.numofparts}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationPartsDesc">
                <Form.Label>Osien Kuvaus:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="partsdesc"
                  value={values.partsdesc}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Button type="submit" disabled={isSubmitting} variant="beweship">
              {isSubmitting ? "Tallennetaan..." : "Tallenna"}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProductDetails;
