const productReducer = (state = null, action) => {
  switch (action.type) {
    case "GET_PRODUCT":
      return action.payload;
    case "EDIT_PRODUCT":
      return { ...state, year: action.payload.year };
    case "ADD_LOCATION":
      return { ...state, locations: [...state.locations, action.payload] };
    case "ADD_CONDITION":
      return { ...state, conditions: [...state.conditions, action.payload] };
    case "ADD_CONSERVATION":
      return {
        ...state,
        conservations: [...state.conservations, action.payload],
      };
    case "EDIT_CONSERVATION":
      return {
        ...state,
        conservations: state.conservations.map((conservation) =>
          conservation.id === action.payload.id ? action.payload : conservation
        ),
      };
    case "DELETE_CONSERVATION":
      return {
        ...state,
        conservations: state.conservations.filter(
          (conservation) => conservation.id !== action.payload
        ),
      };
    case "ADD_DOCUMENT":
      return { ...state, documents: [...state.documents, action.payload] };
    default:
      return state;
  }
};

export default productReducer;
