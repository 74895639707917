import axios from "axios";
const { REACT_APP_BACKEND_URL } = process.env;
const baseUrl = `${REACT_APP_BACKEND_URL}`;

const postOrder = (data, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(`${baseUrl}/org/${orgID}/order/`, data, config);
};

const getOrders = (orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(`${baseUrl}/org/${orgID}/order/`, config);
};

const getOrder = (id, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(`${baseUrl}/org/${orgID}/order/${id}/`, config);
};

const getPublicOrder = (id, orgID) => {
  return axios.get(`${baseUrl}/org/${orgID}/order/${id}/`);
};

const changeOrder = (id, data, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.patch(`${baseUrl}/org/${orgID}/order/${id}/`, data, config);
};

const deleteOrder = (id, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(`${baseUrl}/org/${orgID}/order/${id}/`, config);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  postOrder,
  getOrders,
  getOrder,
  getPublicOrder,
  changeOrder,
  deleteOrder,
};
