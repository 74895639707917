const initialState = {
  productsCount: null
};

const othersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PRODUCTS_COUNT":
      return {
        ...state,
        productsCount: action.payload
      };
    default:
      return state;
  }
};

export default othersReducer;
