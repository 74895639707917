import axios from "axios";
const { REACT_APP_BACKEND_URL } = process.env;
const baseUrl = `${REACT_APP_BACKEND_URL}`;

const postReport = (data, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.post(`${baseUrl}/org/${orgID}/report/`, data, config);
};

const downloadReport = (reportid, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${baseUrl}/org/${orgID}/report/${reportid}/create/`,
    config
  );
};

const getReports = (orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(`${baseUrl}/org/${orgID}/report/`, config);
};

const patchReport = (reportid, logo, orgID, token) => {
  const config = {
    "Content-Type": "multipart/form-data",
    headers: { Authorization: `Token ${token}` },
  };

  let formData = new FormData();
  formData.append("logo", logo);

  return axios.patch(
    `${baseUrl}/org/${orgID}/report/${reportid}/`,
    formData,
    config
  );
};

const deleteReport = (id, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(`${baseUrl}/org/${orgID}/report/${id}/`, config);
};

const downloadReportAll = (reportid, orgID, token) => {
  const config = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.get(
    `${baseUrl}/org/${orgID}/report/${reportid}/xlsx/all/`,
    config
  );
};

const downloadReportCondition = (reportid, orgID, token) => {
  const config = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.get(
    `${baseUrl}/org/${orgID}/report/${reportid}/xlsx/condition/`,
    config
  );
};

const downloadReportWorks = (reportid, orgID, token) => {
  const config = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.get(
    `${baseUrl}/org/${orgID}/report/${reportid}/xlsx/works/`,
    config
  );
};

const downloadReportAccounting = (reportid, orgID, token) => {
  const config = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.get(
    `${baseUrl}/org/${orgID}/report/${reportid}/xlsx/accounting/`,
    config
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  postReport,
  downloadReport,
  getReports,
  patchReport,
  deleteReport,
  downloadReportAll,
  downloadReportCondition,
  downloadReportWorks,
  downloadReportAccounting,
};
