const staffReducer = (state = null, action) => {
  switch (action.type) {
    case "GET_STAFF":
      return { ...state, staff: action.payload };
    case "GET_INVITED_STAFF":
      return { ...state, invitedStaff: action.payload };
    case "INVITE_STAFF":
      const newState = {
        ...state,
        invitedStaff: [...state.invitedStaff, action.payload],
      };
      return newState;
    case "CANCEL_INVITED_STAFF":
      const newState2 = {
        ...state,
        invitedStaff: state.invitedStaff.filter(
          (staff) => staff.id !== action.payload.data.id
        ),
      };
      return newState2;

    case "SELECTORGANIZATION":
      return null;
    default:
      return state;
  }
};

export default staffReducer;
