import * as React from 'react';

import { useSnackbar } from 'notistack';
import { IoMdClose } from 'react-icons/io';
import {
  MdDelete,
  MdDeleteForever,
} from 'react-icons/md';
import { useDispatch } from 'react-redux';

import {
  Button as MButton,
  IconButton,
  Tooltip,
} from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { deleteProduct } from '../../store/actions/productActions';

export default function BasicPopover({ artID }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDelete = () => {
    dispatch(deleteProduct(artID))
      .then(() => {
        enqueueSnackbar(`${artID} poistaminen onnistui`, {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(`${artID} poistaminen epäonnistui`, {
          variant: "error",
        });
      });
  };

  return (
    <>
      <Tooltip title="Poista">
        <IconButton aria-label="Poista" onClick={handleClick}>
          <MdDelete />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography sx={{ p: 2 }}>Olet poistamassa tämän</Typography>
        <MButton
          className="mx-3 mb-3"
          variant="outlined"
          endIcon={<IoMdClose />}
          onClick={handleClose}
        >
          Peruuta
        </MButton>
        <MButton
          className="mx-3 mb-3"
          variant="outlined"
          color="error"
          endIcon={<MdDeleteForever />}
          onClick={handleDelete}
        >
          Poista
        </MButton>
      </Popover>
    </>
  );
}
