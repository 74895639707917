import { useEffect } from 'react';

import {
  Container,
  Nav,
} from 'react-bootstrap';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Link,
  useParams,
} from 'react-router-dom';

import {
  Alert,
  CardActionArea,
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import logo from '../../assets/beweshiplogo.png';
import { getPublicOrder } from '../../store/actions/ordersActions';
import { defaultImage } from '../../utils/HelperFunction';

const PublicOrder = () => {
  const { orgId, orderId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPublicOrder(parseInt(orderId), parseInt(orgId)));
  }, [dispatch, orderId, orgId]);

  const orders = useSelector((state) => state.orders);
  let order;
  if (orders && orders.length > 0) {
    order = orders.find((order) => order.id === parseInt(orderId));
  }

  if (!order) {
    return <div>Tilauksen tietoja ei löytynyt</div>;
  }

  const cart = order.art;

  return (
    <Container>
      <Nav.Link as={Link} to="/" className="p-0">
        <CardMedia
          component="img"
          height="80"
          sx={{ objectFit: "contain", width: "unset" }}
          image={logo}
          alt="Beweship Logo"
        />
      </Nav.Link>
      <h1>Kohde</h1>
      <TableContainer>
        <Table>
          <TableBody>
          {cart && cart.length > 0 ? (
            cart.map((product) => (
              <TableRow key={product.art_id}>
                <TableCell component="th" scope="row">
                  <div className="unset-img">
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        alt={`${product.title} image`}
                        className="custom-img"
                        image={defaultImage(product.documents)}
                      />
                    </CardActionArea>
                  </div>
                </TableCell>
                <TableCell>
                  <p>{product.art_id}</p>
                </TableCell>
                <TableCell>
                  <p>{product.title}</p>
                  <p>{product.artist}</p>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <Alert severity="warning" className="mt-5">
              Kohde on tyhjä
            </Alert>
            )}
            </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default PublicOrder;
