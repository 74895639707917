import React from 'react';

import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';

import {
  CardActionArea,
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { AddAttachment } from '../../../Modals/AddAttachment';

const Attachments = () => {
  const [show, setShow] = React.useState(false);
  const documents = useSelector((state) => state.product.documents);

  return (
    <>
      <Row>
        <Col>
          <Typography variant="h4" component="h1">
            Liitteet
          </Typography>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button variant="beweship" onClick={() => setShow(true)}>
            Lisää
          </Button>
        </Col>
      </Row>

      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableBody>
            {documents && documents.map((document) => (
              <TableRow key={document.id}>
                <TableCell component="th" scope="row">
                  <div className="unset-img">
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        image={document.image}
                        className="custom-img"
                      />
                    </CardActionArea>
                  </div>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {document.description} 
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AddAttachment show={show} handleClose={() => setShow(false)} />
    </>
  );
};

export default Attachments;
