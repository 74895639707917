import React from "react";
import Layout from "../components/Layouts/Layout";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import ProductBoard from "../components/Products/ProductBoard";
import { roleEnum } from "../utils/Constant";
import { getFullName } from "../utils/HelperFunction";
const Index = () => {
  const user = useSelector((state) => state.auth.user);
  const currentOrganization = useSelector(
    (state) => state.auth.currentOrganization
  );

  return (
    <Layout>
      <Container>
        {user && <h4>Tervetuloa {getFullName(user)} - tässä teoksesi</h4>}
        {currentOrganization && (
          <h4> Organisaatiosi: {currentOrganization.name}</h4>
        )}
        {currentOrganization && (
          <h4>Roolisi: {roleEnum[currentOrganization.user_type]}</h4>
        )}

        <ProductBoard />
      </Container>
    </Layout>
  );
};

export default Index;
