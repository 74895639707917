import React from "react";
import { Typography } from "@mui/material";
import { Formik } from "formik";
import { Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateProduct } from "../../../../store/actions/productActions";
import { useSnackbar } from "notistack";
import { InputGroup } from "react-bootstrap";

const Others = ({ product }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmit = (values) => {
    setIsSubmitting(true);
    dispatch(updateProduct(values))
      .then(() => {
        setIsSubmitting(false);
        enqueueSnackbar(`${product.art_id} päivitetty`, {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(`${product.art_id} päivitys epäonnistui`, {
          variant: "error",
        });
        setIsSubmitting(false);
      });
  };
  return (
    <>
      <Typography variant="h4" component="h1">
        Muut tiedot
      </Typography>

      <Formik
        initialValues={{
          id: product.id,
          frame_height: product.frame_height,
          frame_depth: product.frame_depth,
          frame_width: product.frame_width,
          frame_condition: product.frame_condition,
          background_cover: product.background_cover,
          cover_glass: product.cover_glass,
          hanginginst: product.hanginginst,
          addinfo: product.addinfo,
        }}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3 mt-5">
              <Typography variant="h6" component="h2">
                Kehysmitat
              </Typography>
              <Form.Group as={Col} md="4" controlId="validationHeight">
                <Form.Label>Korkeus</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    step=".01"
                    name="frame_height"
                    value={values.frame_height}
                    onChange={handleChange}
                    isInvalid={!!errors.height}
                  />
                  <InputGroup.Text id="inputGroupPrepend">cm</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationWidth">
                <Form.Label>Leveys</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    step=".01"
                    name="frame_width"
                    value={values.frame_width}
                    onChange={handleChange}
                    isInvalid={!!errors.width}
                  />
                  <InputGroup.Text id="inputGroupPrepend">cm</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationDepth">
                <Form.Label>Syvyys</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="number"
                    step=".01"
                    name="frame_depth"
                    value={values.frame_depth}
                    onChange={handleChange}
                    isInvalid={!!errors.depth}
                  />
                  <InputGroup.Text id="inputGroupPrepend">cm</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationBackgroundCover">
                <Form.Label>Taustasuoja</Form.Label>
                <Form.Control
                  type="text"
                  name="background_cover"
                  value={values.background_cover}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCoverGlass">
                <Form.Label>Suojalasi</Form.Label>
                <Form.Control
                  type="text"
                  name="cover_glass"
                  value={values.cover_glass}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationFrameCondition">
                <Form.Label>Kehyksen kunto</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="frame_condition"
                  value={values.frame_condition}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationHanginginst">
                <Form.Label>Ripustusohje</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="hanginginst"
                  value={values.hanginginst}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="validationAddInfo">
                <Form.Label>Muuta</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="addinfo"
                  value={values.addinfo}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Button type="submit" disabled={isSubmitting} variant="beweship">
              {isSubmitting ? "Tallennetaan..." : "Tallenna"}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Others;
