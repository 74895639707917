import { Table, TableBody, TableContainer, Paper } from "@mui/material";
import EnhancedTableHead from "./EnhancedTableHead";
import { useState } from "react";
import { stableSort, getComparator } from "../../utils/HelperFunction";
import ProductRow from "./ProductRow";

export default function EnhancedTable({ products }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {products &&
                stableSort(products, getComparator(order, orderBy)).map(
                  (product) => <ProductRow key={product.id} product={product} />
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
