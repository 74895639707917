import React from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { cancelInvitedStaff } from '../../store/actions/staffActions';
import { roleEnum } from '../../utils/Constant';
import { stringAvatar } from '../../utils/stringAvatar';

const InvitedTable = ({ rows }) => {
  const dispatch = useDispatch();
  const handleDelete = (id) => {
    dispatch(cancelInvitedStaff(id));
  };

  return (
    <TableContainer className="mb-5">
      <Table aria-label="Invited table">
        <TableHead>
          <TableRow>
            <TableCell colSpan="2">INVITED USERS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Row>
                  <Col className="d-flex align-items-center" xs="auto">
                    <Avatar {...stringAvatar(`${row.email}`)} />
                  </Col>

                  <Col>
                    <Typography gutterBottom variant="body2" component="div">
                      {row.email}
                    </Typography>
                  </Col>
                </Row>
              </TableCell>
              <TableCell align="right">
                <Button
                  sx={{
                    "&:hover": {
                      color: "red",
                    },
                  }}
                  onClick={() => handleDelete(row.id)}
                >
                  Peruuta
                </Button>
                <Chip
                  sx={{ fontSize: 14, fontWeight: 500 }}
                  label={roleEnum[row.user_type]}
                  color="primary"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvitedTable;
