import { useState } from "react";
import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Badge, Button } from "@mui/material";
import { MdDelete } from "react-icons/md";
import { FaTruck } from "react-icons/fa";
import { Row } from "react-bootstrap";
import { GrFormNextLink } from "react-icons/gr";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeItemFromCart } from "../../store/actions/cartAction";
import { defaultImage } from "../../utils/HelperFunction";
import { Table } from "react-bootstrap";

const ShoppingCard = () => {
  const [showShoppingCart, setShowShoppingCart] = useState(false);
  const InCart = useSelector((state) => state.cart.arts);
  const dispatch = useDispatch();

  const handleOpenShoppingCart = (event) => {
    setShowShoppingCart(event.currentTarget);
  };

  const handleCloseShoppingCart = () => {
    setShowShoppingCart(null);
  };

  const handleRemoveFromCart = (productId) => {
    dispatch(removeItemFromCart(productId));
  };

  let numberOfItems = 0;
  if (InCart) {
    numberOfItems = InCart.length;
  }

  return (
    <Box sx={{ flexGrow: 0, marginRight: 5 }}>
      <Tooltip title="Avaa aktiivinen kohde">
        <IconButton onClick={handleOpenShoppingCart} sx={{ p: 0 }}>
          <Badge badgeContent={numberOfItems} color="error">
            <FaTruck />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        sx={{ mt: "45px" }}
        id="shopping-cart-menu"
        anchorEl={showShoppingCart}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(showShoppingCart)}
        onClose={handleCloseShoppingCart}
      >
        <Typography variant="h6" className="mx-3">
          Kohde
        </Typography>
        <Table>
          <tbody>
            {InCart &&
              InCart.map((item) => (
                <tr key={item.id}>
                  <td>
                    <CardMedia
                      component="img"
                      alt={`${item.title}`}
                      image={defaultImage(item.documents)}
                      className="shopping-cart-image"
                    />
                  </td>
                  <td>
                    <Row>
                      <Col md={8}>{item.title}</Col>
                      <Col md={4}>
                        <IconButton
                          onClick={() => handleRemoveFromCart(item.id)}
                        >
                          <MdDelete />
                        </IconButton>
                      </Col>
                    </Row>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <MenuItem>
          <Button
            variant="contained"
            endIcon={<GrFormNextLink />}
            component={Link}
            to={`/order/`}
          >
            Siirry aktiivisenkohteen tietoihin
          </Button>
        </MenuItem>
      </Menu>
    </Box>
  );
};
export default ShoppingCard;
