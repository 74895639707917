import Badge from '@mui/material/Badge';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const sideList = [
  {
    title: "Perustiedot",
    index: "details",
  },
  {
    title: "Sijainti",
    index: "location",
  },
  {
    title: "Kuntotiedot",
    index: "condition",
  },
  {
    title: "Konservointitiedot",
    index: "conservation",
  },
  {
    title: "Muut tiedot",
    index: "other",
  },
  {
    title: "Liitetiedostot",
    index: "attachments",
  },
];

const SideTabs = (props) => {
  const { selectedIndex, setSelectedIndex, documents } = props;

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <List component="nav" aria-label="side menu">
      {sideList?.map((item, index) => (
        <ListItemButton
          key={index}
          selected={selectedIndex === item.index}
          onClick={() => handleListItemClick(item.index)}
        >
          <ListItemText primary={item.title} />
          {item.index === "attachments" && (
            <Badge badgeContent={documents?.length > 0 ? documents?.length : "0"} color="primary">
            </Badge>
          )}
        </ListItemButton>
      ))}
    </List>
  );
};

export default SideTabs;
