import React from 'react';

import { useSnackbar } from 'notistack';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { addLocation } from '../../store/actions/productActions';

export const AddLocation = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [location, setLocation] = React.useState(null);
  const [locationDetail, setLocationDetail] = React.useState(null);

  const product = useSelector((state) => state.product);

  const handleSubmit = () => {
    const id = product.id;
    const data = {
      location,
      location_detail: locationDetail,
    };
    if (!location) {
      enqueueSnackbar("Uusi sijainti puutu", {
        variant: "error",
      });
    }
    if (location) {
      dispatch(addLocation(id, data))
        .then(() => {
          setLocation(null);
          setLocationDetail(null);
          enqueueSnackbar("Lisäys onnistui", { variant: "success" });
          handleClose();
        })
        .catch((error) => enqueueSnackbar(`${error}`, { variant: "error" }));
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Lisää Sijainti</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="validationLocation">
              <Form.Label>Uusi sijainti</Form.Label>
              <Form.Control
                type="text"
                name="location"
                onChange={(event) => setLocation(event.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="validationLocationDetails">
              <Form.Label>Tarkenne</Form.Label>
              <Form.Control
                type="text"
                name="location_details"
                onChange={(event) => setLocationDetail(event.target.value)}
              />
            </Form.Group>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button variant="danger" onClick={handleClose} className="mx-3">
                Peruuta
              </Button>
              <Button
                variant="primary"
                onClick={(event) => handleSubmit(event)}
              >
                Tallenna
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
