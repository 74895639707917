export const roleEnum = {
  ET: 'Muokkaaja',
  VI: 'Katsoja',
  OW: 'Pääkäyttäjä',
  CL: 'Perus Käyttäjä',
  IE: 'Inventori Työntekijä',
};

export const conditionEnum = {
  UN: 'Ei Tiedossa',
  SU: 'Välttävä',
  BA: 'Huono',
  SA: 'Tyydyttävä',
  GO: 'Hyvä',
  EX: 'Erinomainen',
};

export const conservationEnum = {
  NU: 'Ei Kiireellinen',
  DI: 'Välitön',
  NE: 'Lähitulevaisuudessa',
  OT: 'Muu',
  UN: 'Ei tiedossa',
  NN: 'Ei Tarvetta',
};
