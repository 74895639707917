import axios from "axios";
const { REACT_APP_BACKEND_URL } = process.env;
const baseUrl = `${REACT_APP_BACKEND_URL}`;

const getHistory = (orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(`${baseUrl}/org/${orgID}/history/`, config);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getHistory };
