import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "@mui/material/Button";
import Layout from "../components/Layouts/Layout";
import Paper from "@mui/material/Paper";
import InvitedTable from "../components/Staff/InvitedTable";
import ActiveUserTable from "../components/Staff/ActiveUserTable";
import { InviteStaff } from "../components/Modals/InviteStaff";
import { useSelector, useDispatch } from "react-redux";
import { getStaff, getInvitedStaff } from "../store/actions/staffActions";

const Staffs = () => {
  const [show, setShow] = React.useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStaff());
    dispatch(getInvitedStaff());
  }, [dispatch]);

  const staff = useSelector((state) => state.staff?.staff);
  const invitedStaff = useSelector((state) => state.staff?.invitedStaff);

  const auth = useSelector((state) => state.auth);

  if (!staff) {
    return <div>Loading...</div>;
  }

  const isOwner = (auth, staff) => {
    const userType = auth.currentOrganization.user_type;

    const isOwner = userType === "OW";

    const isSuperUser = auth.currentOrganization.user_type === "SUPER_USER";
    if (isOwner || isSuperUser) {
      return true;
    }
    return false;
  };

  return (
    <Layout>
      <Container>
        <Paper elevation={3} className="p-3">
          <Row className="mb-3">
            <Col>
              <h2>Staff users</h2>
            </Col>
            {isOwner(auth, staff) && (
              <Col className="d-flex justify-content-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShow(true)}
                >
                  Invite people
                </Button>
              </Col>
            )}
          </Row>
          {isOwner(auth, staff) && <InvitedTable rows={invitedStaff} />}
          <ActiveUserTable rows={staff} />
        </Paper>
        <InviteStaff show={show} setShow={setShow} />
      </Container>
    </Layout>
  );
};
export default Staffs;
