export const EditPermission = (user_type) => {
  return (
    user_type === "SUPER_USER" ||
    user_type === "OW" ||
    user_type === "ET" ||
    user_type === "IE" ||
    user_type === null
  );
};

export const DeletePermission = (user_type) => {
  return user_type === "SUPER_USER" || user_type === "OW" || user_type === "IE";
};

export const OrderPermission = (user_type) => {
  return (
    user_type === "SUPER_USER" ||
    user_type === "OW" ||
    user_type === "CL" ||
    user_type === "ET" ||
    user_type === null
  );
};

export const CreatePermission = (user_type) => {
  return (
    user_type === "SUPER_USER" ||
    user_type === "OW" ||
    user_type === "ET" ||
    user_type === null
  );
};

export const ViewLogPermission = (user_type) => {
  return (
    user_type === "SUPER_USER" ||
    user_type === "OW" ||
    user_type === "ET" ||
    user_type === "IE" ||
    user_type === null
  );
};

export const ViewReportPermission = (user_type) => {
  return user_type === "SUPER_USER" || user_type === "OW";
};

export const ViewOrdersPermission = (user_type) => {
  return (
    user_type === "SUPER_USER" ||
    user_type === "OW" ||
    user_type === "CL" ||
    user_type === "ET" ||
    user_type === null
  );
};

export const ViewStaffPermission = (user_type) => {
  return (
    user_type === "SUPER_USER" ||
    user_type === "OW" ||
    user_type === "ET" ||
    user_type === "CL" ||
    user_type === "IE" ||
    user_type === null
  );
};

export const AcceptOrderPermission = (user_type) => {
  return user_type === "SUPER_USER" || user_type === "OW";
};
