import React from 'react';

import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';

import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Typography from '@mui/material/Typography';

import { conservationEnum } from '../../../../utils/Constant';
import {
  convertDate,
  getFullName,
} from '../../../../utils/HelperFunction';
import { AddConservation } from '../../../Modals/AddConservation';
import { EditConservation } from '../../../Modals/EditConservation';
import ConfirmDeleteConservation
  from './Conservation/ConfirmDeleteConservation';

const Conservation = () => {
  // state for showing model
  const [show, setShow] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [editId, setEditId] = React.useState(false);

  const product = useSelector((state) => state.product);
  const conservations = useSelector((state) => state.product.conservations);


  conservations.sort((a, b) => {
    return new Date(b.created) - new Date(a.created);
  });

  //handle edit
  const handleEdit = (id) => {
    setEditId(id);
    setShowEdit(true);
  };

  return (
    <>
      <Row>
        <Col>
          <Typography variant="h4" component="h1">
            Konservointitiedot
          </Typography>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button variant="beweship" onClick={() => setShow(true)}>
            Lisää
          </Button>
        </Col>
      </Row>

      <Timeline position="right">
        {conservations.map((conservation) => (
          <TimelineItem key={conservation.id}>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              <Typography>{convertDate(conservation.created)}</Typography>
              <Typography>{getFullName(conservation.person)}</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography variant="h6" component="span">
                {conservationEnum[conservation.conservation_class]}
              </Typography>
              <Typography>
                {conservation.conservation_procedure_info}
              </Typography>
              <Button variant="outline-primary" onClick={() => handleEdit(conservation.id)}>
                <MdEdit />
              </Button>
              <ConfirmDeleteConservation artId={product.id} conservationId={conservation.id} />
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <AddConservation show={show} handleClose={() => setShow(false)} />
      <EditConservation show={showEdit} handleClose={() => setShowEdit(false)} id={editId} />
    </>
  );
};
export default Conservation;
