import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Button, Grid, TextField } from '@mui/material/';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { recoverPassword } from '../../store/actions/authActions';

const theme = createTheme();

const ForgotPassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email').toLocaleLowerCase();

    dispatch(recoverPassword(email))
      .then(() => {
        enqueueSnackbar('User created successfully', { variant: 'success' });
        navigate('/');
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          enqueueSnackbar('Tarkista sähköpostiosoite', { variant: 'error' });
        } else if (err.response?.status === 500) {
          enqueueSnackbar('Palvelimessa tapahtui virhe', { variant: 'error' });
        } else {
          enqueueSnackbar('Yritä myöhemmin uudelleen.', { variant: 'error' });
        }
      });
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Palauta salasana
          </Typography>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3, minWidth: 300 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Sähköpostiosoite"
                  name="email"
                  variant="filled"
                  autoComplete="email"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Lähetä
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
export default ForgotPassword;
