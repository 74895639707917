import axios from 'axios';

const { REACT_APP_BACKEND_URL } = process.env;
const baseUrl = `${REACT_APP_BACKEND_URL}`;

const getProducts = (id, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = axios.get(`${baseUrl}/org/${id}/art/`, config);
  return response;
};

const getProductsByPage = (id, page, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = axios.get(`${baseUrl}/org/${id}/art/?page=${page}`, config);
  return response;
};

const getProduct = (id, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(`${baseUrl}/org/${orgID}/art/${id}/`, config);
};

const postProduct = (token, orgID, data) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(`${baseUrl}/org/${orgID}/art/`, data, config);
};

const deleteProduct = (id, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(`${baseUrl}/org/${orgID}/art/${id}/`, config);
};

const addLocation = (data, id, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(`${baseUrl}/art/${id}/location/`, data, config);
};

const addDocument = (data, description, id, orgID, token) => {
  const config = {
    "Content-Type": "multipart/form-data",
    headers: { Authorization: `Token ${token}` },
  };

  let formData = new FormData();
  formData.append("image", data);
  formData.append("description", description); 

  return axios.post(
    `${baseUrl}/org/${orgID}/art/${id}/document/`,
    formData,
    config
  );
};

const patchProduct = (id, data, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.patch(`${baseUrl}/org/${orgID}/art/${id}/`, data, config);
};

const postLocation = (id, data, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${baseUrl}/org/${orgID}/art/${id}/location/`,
    data,
    config
  );
};

const postCondition = (id, data, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${baseUrl}/org/${orgID}/art/${id}/condition/`,
    data,
    config
  );
};

const postConservation = (id, data, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${baseUrl}/org/${orgID}/art/${id}/conservation/`,
    data,
    config
  );
};

const editConservation = (artId, conserId, data, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.patch(
    `${baseUrl}/org/${orgID}/art/${artId}/conservation/${conserId}/`,
    data,
    config
  );
}

const deleteConservation = (id, artId, orgId, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(`${baseUrl}/org/${orgId}/art/${artId}/conservation/${id}/`, config);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getProducts,
  getProductsByPage,
  getProduct,
  postProduct,
  addDocument,
  addLocation,
  patchProduct,
  deleteProduct,
  postLocation,
  postCondition,
  postConservation,
  deleteConservation,
  editConservation
};
