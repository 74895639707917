import {
  useEffect,
  useState,
} from 'react';

import { useSnackbar } from 'notistack';
import {
  Button,
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  Alert,
  CardActionArea,
  CardMedia,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import Layout from '../../components/Layouts/Layout';
import {
  addItemToCart,
  contineuDraft,
} from '../../store/actions/cartAction';
import {
  changeOrder,
  deleteOrder,
  getOrder,
} from '../../store/actions/ordersActions';
import { AcceptOrderPermission } from '../../utils/FeaturePermission';
import {
  convertDate,
  defaultImage,
  getFullName,
} from '../../utils/HelperFunction';

const ROOT_URL = process.env.REACT_APP_ROOT_URL

const OrderDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isPublic, setIsPublic] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(getOrder(parseInt(id)));
  }, [dispatch, id]);

  const currentOrganization = useSelector(
    (state) => state.auth.currentOrganization
  );

  const orders = useSelector((state) => state.orders);
  let order;
  if (orders && orders.length > 0) {
    order = orders.find((order) => order.id === parseInt(id));
  }

  useEffect(() => {
    if (order && order.public) {
      setIsPublic(true);
    }
  }, [order]);

  if (!order) {
    return <div>Tilauksen tietoja ei löytynyt</div>;
  }

  const cart = order.art;

  const hanlePublic = (e) => {
    const data = {
      public: e.target.checked,
    };

    dispatch(changeOrder(order.id, data));
    setIsPublic(e.target.checked);
  };

  const handleAccept = (e) => {
    const data = {
      accepted: true,
    };

    dispatch(changeOrder(order.id, data));
  };

  const handleSubmit = (e) => {
    let isDraft = false;
    if (e.target.value === "Draft") {
      isDraft = true;
    }

    const data = {
      draft: isDraft,
    };

    dispatch(changeOrder(order.id, data));
  };

  const handleDelete = (e) => {
    dispatch(deleteOrder(order.id));
    navigate("/orders/");
  };

  const hanldeContinueDraft = () => {
    const items = order.art;

    items.forEach((item) => {
      dispatch(addItemToCart(item));
    });

    const orderData = {
      id: order.id,
      location: order.location,
      location_detail: order.location_detail,
      note: order.note,
    };

    dispatch(contineuDraft(orderData))
      .then(() => {
        enqueueSnackbar(`Tuotteet lisätty kohteeseen`, {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(`tilauksen jatkaminen epäonnistui`, {
          variant: "error",
        });
      });
  };

  return (
    <Layout>
      <Container>
        <h1>Tilauksen tiedot</h1>

        <Row md={8}>
          <Col>Tilauspvm, Tilausaika</Col>
          <Col>{convertDate(order.modified)}</Col>
        </Row>
        <Row md={8}>
          <Col>Mihin?</Col>
          <Col>
            {order.location}, {order.location_detail}
          </Col>
        </Row>
        <Row md={8}>
          <Col>Lisää tietoa</Col>
          <Col>{order.note}</Col>
        </Row>
        <Row md={8}>
          <Col>Tilaaja</Col>
          <Col>{getFullName(order.ordered_by)}</Col>
        </Row>
        <Row md={8}>
          <Col>Status</Col>
          <Col>
            {order.draft
              ? "Luonnos"
              : order.accepted
              ? "Hyväksytty"
              : "Ei ole hyväksytty"}
          </Col>
        </Row>
        <Row md={8}>
          <Col>Julkinen</Col>
          <Col>
            <Switch
              checked={isPublic ? true : false}
              onChange={hanlePublic}
              inputProps={{ "aria-label": "public" }}
            />
          </Col>
        </Row>
        {isPublic && (
          <Row md={8}>
            <Col>Julkinen Linkki</Col>
            <Col>
              <a
                href={`${ROOT_URL}/order/public/${currentOrganization.id}/${order.id}/`}
              >{`${ROOT_URL}/order/public/${currentOrganization.id}/${order.id}/`}</a>
            </Col>
          </Row>
        )}

        <Row md={8} className="mt-4">
          {order.draft ? (
            <>
              <Col className="d-flex justify-content-end">
                <Button
                  variant="danger"
                  className="mr-2"
                  onClick={handleDelete}
                >
                  Poista
                </Button>

                <Button variant="secondary" onClick={hanldeContinueDraft}>
                  Jatkaa
                </Button>
              </Col>
              <Col>
                <Button variant="success" value="Submit" onClick={handleSubmit}>
                  Lähetä Tilaus
                </Button>
              </Col>
            </>
          ) : order.accepted ? (
            <></>
          ) : (
            AcceptOrderPermission(currentOrganization.user_type) && (
              <>
                <Col className="d-flex justify-content-end">
                  <Button variant="danger" onClick={handleDelete}>
                    Poista
                  </Button>
                </Col>
                <Col>
                  <Button variant="success" onClick={handleAccept}>
                    Hyväksy
                  </Button>
                </Col>
              </>
            )
          )}
        </Row>

        <h1>Kohde</h1>
        <TableContainer>
          <Table>
            <TableBody>
              {cart && cart.length > 0 ? (
                cart.map((product) => (
                  <TableRow key={product.art_id}>
                    <TableCell className="pl-0" scope="row">
                      <div className="unset-img">
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            alt={`${product.title} image`}
                            className="custom-img"
                            image={defaultImage(product.documents)}
                          />
                        </CardActionArea>
                      </div>
                    </TableCell>
                    <TableCell>
                      <p>{product.art_id}</p>
                    </TableCell>
                    <TableCell>
                      <p>{product.title}</p>
                      <p>{product.artist}</p>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <Alert severity="warning" className="mt-5">
                  Kohde on tyhjä
                </Alert>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Layout>
  );
};

export default OrderDetails;
