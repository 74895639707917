import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  CardActionArea,
  CardMedia,
  Tooltip,
  IconButton,
  TableBody,
} from "@mui/material";
import { Alert } from "@mui/material";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import Layout from "../../components/Layouts/Layout";
import { useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { makeOrder, removeItemFromCart } from "../../store/actions/cartAction";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { defaultImage } from "../../utils/HelperFunction";

const Order = () => {
  const cart = useSelector((state) => state.cart.arts);
  const draftDetails = useSelector((state) => state.cart.draft);
  const [location, setLocation] = useState(
    draftDetails ? draftDetails.location : ""
  );
  const [locationDetails, setLocationDetails] = useState(
    draftDetails ? draftDetails.location_detail : ""
  );
  const [note, setNote] = useState(draftDetails ? draftDetails.note : "");
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    let order = [];

    cart.forEach((item) => {
      order.push(item.id);
    });

    let isDraft = false;
    if (event.target.value === "Draft") {
      isDraft = true;
    }

    const data = {
      orderId: draftDetails?.id,
      draft: isDraft ? true : false,
      art: order,
      location,
      location_detail: locationDetails,
      note,
    };

    dispatch(makeOrder(data))
      .then(() => {
        setLocation("");
        setLocationDetails("");
        setNote("");
        navigate("/");
        localStorage.removeItem("cart");
        enqueueSnackbar("Tilaus onnistui", {
          variant: "success",
        });
      })
      .catch((err) => {
        const errors = err.response.data;

        for (const [key, value] of Object.entries(errors)) {
          enqueueSnackbar(`${key}: ${value}`, {
            variant: "error",
          });
        }
      });
  };

  const handleRemoveFromCart = (productId) => {
    dispatch(removeItemFromCart(productId));
  };

  return (
    <div>
      <Layout>
        <Container>
          <h1>Kohde</h1>
          <TableContainer>
            <Table>
              <TableBody>
                {cart && cart.length > 0 ? (
                  cart.map((product) => (
                    <TableRow key={product.id}>
                      <TableCell component="th" className="pl-0" scope="row">
                        <div className="unset-img">
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              alt={`${product.title} image`}
                              className="custom-img"
                              image={defaultImage(product.documents)}
                            />
                          </CardActionArea>
                        </div>
                      </TableCell>
                      <TableCell>
                        <p>{product.art_id}</p>
                      </TableCell>
                      <TableCell>
                        <p>{product.title}</p>
                        <p>{product.artist}</p>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Poista">
                          <IconButton
                            aria-label="Poista"
                            onClick={() => handleRemoveFromCart(product.id)}
                          >
                            <MdDelete />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <Alert severity="warning" className="mt-5">
                        Kohde on tyhjä
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <h1>Kohteen tiedot</h1>

          <Row>
            <Col md="8">
              <Form noValidate>
                <Row className="mb-3 mt-5">
                  <Form.Group as={Col} md="6" controlId="validationLocation">
                    <Form.Label>Sijainti</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="location"
                        value={location}
                        onChange={(event) => setLocation(event.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="validationLocationDetails"
                  >
                    <Form.Label>Tarkenne</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="locationDetails"
                        value={locationDetails}
                        onChange={(event) =>
                          setLocationDetails(event.target.value)
                        }
                      />
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="validationAdditionalDetails">
                    <Form.Label>Lisää tietoa</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="note"
                      value={note}
                      onChange={(event) => setNote(event.target.value)}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  {draftDetails?.id && (
                    <h6 className="text-end">
                      Tämä on tilaus nro. {draftDetails.id}
                    </h6>
                  )}
                </Row>
                <Row>
                  <Col className="">
                    <Button
                      variant="secondary"
                      value="Draft"
                      disabled={cart?.length === 0}
                      onClick={(event) => handleSubmit(event)}
                    >
                      Tallenna luonnos
                    </Button>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button
                      variant="primary"
                      value="Submit"
                      disabled={cart?.length === 0}
                      onClick={(event) => handleSubmit(event)}
                    >
                      Tilaa
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Layout>
    </div>
  );
};

export default Order;
