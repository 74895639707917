import React from 'react';

import { useSnackbar } from 'notistack';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { addCondition } from '../../store/actions/productActions';

export const AddCondition = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [condition, setCondition] = React.useState("SL");
  const [conditionDetail, setConditionDetail] = React.useState(null);

  const product = useSelector((state) => state.product);

  const handleSubmit = () => {
    const id = product.id;
    const data = {
      condition: conditionDetail,
      condition_class: condition,
    };
    if (condition && condition !== "SL") {
      dispatch(addCondition(id, data))
        .then(() => {
          setCondition(null);
          setConditionDetail(null);
          enqueueSnackbar("Lisäys onnistui", { variant: "success" });
          handleClose();
        })
        .catch((error) => enqueueSnackbar(`${error}`, { variant: "error" }));
    } else {
      enqueueSnackbar("Kunto puutu", { variant: "error" });
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Lisää Kunto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="validationCondition">
              <Form.Label>Kuntoluokka</Form.Label>
              <Form.Select
                aria-label="Kuntoluokka"
                onChange={(event) => setCondition(event.target.value)}
              >
                <option value="SL">Valitse kunto</option>
                <option value="EX"> Erinomainen </option>
                <option value="GO"> Hyvä </option>
                <option value="SA"> Tyydyttävä </option>
                <option value="BA"> Huono </option>
                <option value="SU"> Välttävä </option>
                <option value="UN">Ei tiedossa</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="validationConditionDetails">
              <Form.Label>Kunto</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="signature_info"
                onChange={(event) => setConditionDetail(event.target.value)}
              />
            </Form.Group>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button variant="danger" onClick={handleClose} className="mx-3">
                Peruuta
              </Button>
              <Button
                variant="primary"
                onClick={(event) => handleSubmit(event)}
              >
                Tallenna
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
