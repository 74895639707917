import * as React from 'react';

import { Button } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { MdDeleteForever } from 'react-icons/md';
import { useDispatch } from 'react-redux';

import { Button as MButton } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { deleteReport } from '../../store/actions/reportActions';

export default function BasicPopover(reportID) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDelete = () => {
    dispatch(deleteReport(reportID.reportID));
  };

  return (
    <>
      <Button
        aria-describedby={id}
        variant="danger"
        className="mx-2"
        onClick={handleClick}
      >
        Poista
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography sx={{ p: 2 }}>Olet poistamassa tämän raportin</Typography>
        <MButton
          className="mx-3 mb-3"
          variant="outlined"
          endIcon={<IoMdClose />}
          onClick={handleClose}
        >
          Peruuta
        </MButton>
        <MButton
          className="mx-3 mb-3"
          variant="outlined"
          color="error"
          endIcon={<MdDeleteForever />}
          onClick={handleDelete}
        >
          Poista
        </MButton>
      </Popover>
    </>
  );
}
