import { Col } from "react-bootstrap";
import {
  Card,
  CardHeader,
  CardContent,
  Chip,
  Stack,
  Tooltip,
  IconButton,
  Typography,
  CardActions,
  CardMedia,
} from "@mui/material";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import noImage from "../../assets/no-image.svg";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { showImage } from "../../store/actions/modalActions";
import { conditionEnum, conservationEnum } from "../../utils/Constant";
import { MdAddShoppingCart } from "react-icons/md";
import { addItemToCart } from "../../store/actions/cartAction";
import {
  EditPermission,
  DeletePermission,
  OrderPermission,
} from "../../utils/FeaturePermission";
import { useSelector } from "react-redux";
import ConfirmDeleteArt from "../mui/ConfirmDeleteArt";

const Gcard = ({ product }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const auth = useSelector((state) => state.auth);

  const img = product.documents[0]?.image?.replace(/["]+/g, "");

  const currentLocation = product.locations.sort((a, b) =>
    a.id < b.id ? 1 : -1
  )[0];

  const handleShowImage = (title) => {
    const image = img ? img : noImage;
    dispatch(showImage(image, title));
  };

  const handleAddItem = () => {
    dispatch(addItemToCart(product))
      .then(() => {
        enqueueSnackbar("Tuote lisätty kohteeseen", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Tuote on jo kohdessa", {
          variant: "error",
        });
      });
  };

  return (
    <Col lg={4} md={6} sm={12} className="mt-4" key={product?.id}>
      <Card className="text-center">
        <CardHeader title={product?.title} subheader={product?.artist} />
        <CardMedia
          component="img"
          alt={`${product.name} image`}
          image={img ? img : noImage}
          onClick={() => handleShowImage(product.title)}
        />
        <CardContent>
          <Typography gutterBottom variant="body1" component="div">
            {product?.width} x {product?.height} x {product?.depth} cm
          </Typography>
          <Typography variant="body2" component="div">
            {product?.art_id}
          </Typography>
          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              {product.mainclass && (
                <Tooltip title="Kategoria" placement="top" arrow>
                  <Chip label={product?.mainclass} variant="outlined" />
                </Tooltip>
              )}
              {currentLocation?.location && (
                <Tooltip title="Sijainti" placement="top" arrow>
                  <Chip label={currentLocation?.location} variant="outlined" />
                </Tooltip>
              )}
            </Stack>
            <Stack direction="row" spacing={1}>
              {product.conditions[0] && (
                <Tooltip title="Kunto" placement="top" arrow>
                  <Chip
                    label={
                      conditionEnum[product.conditions[0]?.condition_class]
                    }
                    variant="outlined"
                    color="warning"
                  />
                </Tooltip>
              )}
              {product.conservations[0] && (
                <Tooltip title="Konser" placement="top" arrow>
                  <Chip
                    label={
                      conservationEnum[
                        product.conservations[0]?.conservation_class
                      ]
                    }
                    variant="outlined"
                    color="error"
                  />
                </Tooltip>
              )}
            </Stack>
          </Stack>
        </CardContent>

        <CardActions sx={{ justifyContent: "center" }}>
          {EditPermission(auth.currentOrganization.user_type) && (
            <Link to={`Product/EditProduct/${product.id}`} target="_blank">
              <Tooltip title="Muokka tietoja">
                <IconButton aria-label="edit">
                  <MdEdit />
                </IconButton>
              </Tooltip>
            </Link>
          )}
          {DeletePermission(auth.currentOrganization.user_type) && (
            <ConfirmDeleteArt artID={product.id} />
          )}
          {OrderPermission(auth.currentOrganization.user_type) && (
            <Tooltip title="Lisää kohteeseen">
              <IconButton
                aria-label="Lisää kohteeseen"
                onClick={handleAddItem}
              >
                <MdAddShoppingCart />
              </IconButton>
            </Tooltip>
          )}
        </CardActions>
      </Card>
    </Col>
  );
};
export default Gcard;
