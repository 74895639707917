import axios from 'axios';
const { REACT_APP_BACKEND_URL } = process.env;
const baseUrl = `${REACT_APP_BACKEND_URL}`;

const getUserInfo = async (token) => {
  const configs = {
    headers: { Authorization: token },
  };
  const response = await axios.get(`${baseUrl}/accountsuser/`, configs);
  return response.data;
};

const getUserOrganizations = async (token) => {
  const configs = {
    headers: { Authorization: token },
  };
  const response = await axios.get(`${baseUrl}/org/`, configs);
  return response.data;
};

const getToken = async (credentials) => {
  const response = await axios.post(`${baseUrl}/accountslogin/`, credentials);
  return response.data;
};

const createUser = async (user) => {
  const response = await axios.post(`${baseUrl}/accounts/registration/`, user);
  return response.data;
};

const recoverPassword = async (data) => {
  const response = await axios.post(`${baseUrl}/accountspassword/reset/`, data);
  return response.data;
};

const resetPassword = async (data) => {
  const response = await axios.post(`${baseUrl}/accountspassword/reset/confirm/`, data);
  return response.data;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUserInfo,
  getToken,
  createUser,
  getUserOrganizations,
  recoverPassword,
  resetPassword
};
