import Layout from "../components/Layouts/Layout";
import { Container } from "react-bootstrap";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import Inventory from "../components/ReportsPage/Inventory";
import OldReports from "../components/ReportsPage/OldReports";

export default function Reports() {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Layout>
      <Container>
        <Divider />
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Kaikki Teokset" value="1" />
              <Tab label="Vanhat raportit" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Inventory />
          </TabPanel>
          <TabPanel value="2">
            <OldReports />
          </TabPanel>
        </TabContext>
      </Container>
    </Layout>
  );
}
