import staffServices from "../services/staffServices";
import Cookies from "universal-cookie";

const cookies = new Cookies();
export const inviteStaff = (email, role) => {
  return async (dispatch) => {
    const token = cookies.get("jid");
    const orgID = cookies.get("currentorg");
    const data = {
      email,
      user_type: role,
    };
    const staff = await staffServices.postStaff(data, orgID, token);

    await staffServices.sendInvitation(staff.data.id, orgID, token);

    dispatch({
      type: "INVITE_STAFF",
      payload: staff.data,
    });
  };
};

export const getStaff = () => {
  return async (dispatch) => {
    const token = cookies.get("jid");
    const orgID = cookies.get("currentorg");
    const staff = await staffServices.getStaff(orgID, token);
    dispatch({
      type: "GET_STAFF",
      payload: staff.data,
    });
  };
};

export const getInvitedStaff = () => {
  return async (dispatch) => {
    const token = cookies.get("jid");
    const orgID = cookies.get("currentorg");
    const staff = await staffServices.getInvitedStaff(orgID, token);
    dispatch({
      type: "GET_INVITED_STAFF",
      payload: staff.data,
    });
  };
};

export const cancelInvitedStaff = (id) => {
  return async (dispatch) => {
    const token = cookies.get("jid");
    const orgID = cookies.get("currentorg");
    await staffServices.deleteStaff(id, orgID, token);

    dispatch({
      type: "CANCEL_INVITED_STAFF",
      payload: { data: { id } },
    });
  };
};
