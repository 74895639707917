import React, { useEffect } from 'react';

import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useParams } from 'react-router-dom';

import { Paper } from '@mui/material';
import Box from '@mui/material/Box';

import Layout from '../../components/Layouts/Layout';
import TabPanel from '../../components/mui/TabPanel';
import Attachments
  from '../../components/Products/EditProduct/Tabs/Attachments';
import Condition from '../../components/Products/EditProduct/Tabs/Condition';
import Conservation
  from '../../components/Products/EditProduct/Tabs/Conservation';
import Location from '../../components/Products/EditProduct/Tabs/Location';
import Others from '../../components/Products/EditProduct/Tabs/Others';
import Details from '../../components/Products/EditProduct/Tabs/ProductDetails';
import SideTabs from '../../components/Products/EditProduct/Tabs/SideTabs';
import { getProduct } from '../../store/actions/productActions';

const ProductDetails = () => {
  const [selectedIndex, setSelectedIndex] = React.useState("details");
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getProduct(id));
  }, [dispatch, id]);

  const product = useSelector((state) => state.product);
  return (
    <Layout>
      <Container>
        <Row>
          <Col md="12" lg="3" className="d-none d-lg-block">
            <Box
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
              }}
              className="sticky-top"
            >
              <Paper>
                <SideTabs
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  documents={product?.documents}
                />
              </Paper>
            </Box>
          </Col>

          <Col md="12" lg="9">
            <Box
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <Paper sx={{ padding: 2 }}>
                <TabPanel currentTab={selectedIndex} visibleIndex="details">
                  {product && <Details product={product} />}
                </TabPanel>
                <TabPanel currentTab={selectedIndex} visibleIndex="location">
                  <Location />
                </TabPanel>
                <TabPanel currentTab={selectedIndex} visibleIndex="frame">
                  <Location />
                </TabPanel>
                <TabPanel currentTab={selectedIndex} visibleIndex="condition">
                  <Condition />
                </TabPanel>
                <TabPanel
                  currentTab={selectedIndex}
                  visibleIndex="conservation"
                >
                  <Conservation />
                </TabPanel>
                <TabPanel currentTab={selectedIndex} visibleIndex="other">
                  {product && <Others product={product} />}
                </TabPanel>
                <TabPanel currentTab={selectedIndex} visibleIndex="attachments">
                  <Attachments product={product} />
                </TabPanel>
              </Paper>
            </Box>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ProductDetails;
