import authServices from '../services/authServices';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getUserInfo = () => {
  return async (dispatch) => {
    const token = `Token ${cookies.get('jid')}`;
    const user = await authServices.getUserInfo(`${token}`);
    dispatch({
      type: 'USERINFO',
      payload: {
        user: {
          id: user.id,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          user_type: user.user_type,
        },
      },
    });
  };
};

export const getUserOrganizations = () => {
  return async (dispatch) => {
    const token = `Token ${cookies.get('jid')}`;
    const organizations = await authServices.getUserOrganizations(`${token}`);
    dispatch({
      type: 'USERORGANIZATIONS',
      payload: organizations,
    });
  };
};

export const selectOrganization = (organization) => {
  return async (dispatch) => {
    cookies.set('currentorg', organization.id, { path: '/' });
    localStorage.setItem('cart', JSON.stringify([]));
    dispatch({
      type: 'SELECTORGANIZATION',
      payload: {
        currentOrganization: organization,
      },
    });
  };
};

export const signIn = (credentials) => {
  return async (dispatch) => {
    const getTokenRes = await authServices.getToken(credentials);
    cookies.set('jid', getTokenRes.key, { path: '/' });
    const token = `Token ${cookies.get('jid')}`;
    const user = await authServices.getUserInfo(token);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          id: user.id,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          user_type: user.user_type,
        },
      },
    });
  };
};

export const signOut = () => {
  return async (dispatch) => {
    cookies.remove('jid', { path: '/' });
    cookies.remove('currentorg', { path: '/' });
    localStorage.setItem('cart', JSON.stringify([]));

    dispatch({
      type: 'LOGOUT',
      payload: {
        user: {
          id: null,
          email: null,
          first_name: null,
          last_name: null,
          user_type: null,
        },
      },
    });
  };
};

export const createUser = (user) => {
  const newUser = {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    password: user.password,
    password_again: user.passwordAgain,
    invitation_key: user.inviteCode,
  };
  return async (dispatch) => {
    await authServices.createUser(newUser);
    dispatch({
      type: 'CREATEUSER',
      payload: {},
    });
  };
};

export const recoverPassword = (email) => {
  return async (dispatch) => {
    const data = {
      email,
    };
    await authServices.recoverPassword(data);
    dispatch({
      type: 'CREATEUSER',
      payload: {},
    });
  };
};

export const resetPassword = (credentials) => {
  return async (dispatch) => {
    await authServices.resetPassword(credentials);
    dispatch({
      type: 'RESETPASSWORD',
      payload: {},
    });
  }
}
