const organizationReducer = (state = null, action) => {
  switch (action.type) {
    case "USERORGANIZATIONS":
      return action.payload;
    default:
      return state;
  }
};

export default organizationReducer;
