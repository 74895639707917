function TabPanel(props) {
  const { children, currentTab, visibleIndex, ...other } = props;
  return (
    <>
      {currentTab === visibleIndex && (
        <div
          role="tabpanel"
          id={`tabpanel-${visibleIndex}`}
          aria-labelledby={`tab-${visibleIndex}`}
          {...other}
        >
          {children}
        </div>
      )}
    </>
  );
}

export default TabPanel;
