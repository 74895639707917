import React from 'react';

import { useSnackbar } from 'notistack';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { addConservation } from '../../store/actions/productActions';

export const AddConservation = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [conservationClass, setConservationClass] = React.useState("SL");
  const [conservationProcedureInfo, setConservationProcedureInfo] =
    React.useState(null);

  const product = useSelector((state) => state.product);

  const handleSubmit = () => {
    const id = product.id;
    const data = {
      conservation_class: conservationClass,
      //TODO: Delete conservation_procedure
      conservation_procedure: conservationProcedureInfo,
      conservation_procedure_info: conservationProcedureInfo,
    };
    if (conservationClass && conservationClass !== "SL") {
      dispatch(addConservation(id, data))
        .then(() => {
          setConservationClass(null);
          setConservationProcedureInfo(null);
          enqueueSnackbar("Lisäys onnistui", { variant: "success" });
          handleClose();
        })
        .catch((error) => enqueueSnackbar(`${error}`, { variant: "error" }));
    } else {
      enqueueSnackbar("Kuvaus puutu", { variant: "error" });
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Uusi konservointitoimenpide</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="validationConservationClass">
              <Form.Label>Konservointitarve</Form.Label>
              <Form.Select
                aria-label="ConservationClass"
                onChange={(event) => setConservationClass(event.target.value)}
              >
                <option value="SL">Valitse Konservointitarve</option>
                <option value="NU"> Ei Kiireellinen </option>
                <option value="DI"> Välitön </option>
                <option value="NE"> Lähitulevaisuudessa </option>
                <option value="UN">Ei Tarvetta</option>
                <option value="OT"> Muu </option>
                <option value="UN">Ei tiedossa</option>
              </Form.Select>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group
              as={Col}
              controlId="validationConservationProcedureInfo"
            >
              <Form.Label>Kuvaus</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="ConservationProcedureInfo"
                onChange={(event) =>
                  setConservationProcedureInfo(event.target.value)
                }
              />
            </Form.Group>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button variant="danger" onClick={handleClose} className="mx-3">
                Peruuta
              </Button>
              <Button
                variant="primary"
                onClick={(event) => handleSubmit(event)}
              >
                Tallenna
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
