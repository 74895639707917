import historyServices from "../services/historyServices";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const getHistory = () => {
  return async (dispatch) => {
    const token = cookies.get("jid");
    const orgID = cookies.get("currentorg");
    const history = await historyServices.getHistory(orgID, token);
    dispatch({
      type: "GET_HISTORY",
      payload: history.data,
    });
  };
};
