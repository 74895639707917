import React from "react";
import { Routes, Route } from "react-router-dom";
import Index from "../pages/Index";
import Logs from "../pages/Logs";
import Reports from "../pages/Reports";
import AddProduct from "../pages/Product/AddProduct";
import EditProduct from "../pages/Product/EditProduct";
import Staffs from "../pages/Staffs";
import Order from "../pages/Order/AddOrder";
import Orders from "../pages/Order/Orders";
import OrderDetails from "../pages/Order/OrderDetails";
import PublicOrder from "../pages/Order/PublicOrder";

const Authorized = () => {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/Logs" element={<Logs />} />
      <Route path="/Reports" element={<Reports />} />
      <Route path="/Product/AddProduct" element={<AddProduct />} />
      <Route path="/Product/EditProduct/:id" element={<EditProduct />} />
      <Route path="/Staffs" element={<Staffs />} />
      <Route path="/order" element={<Order />} />
      <Route path="/order/details/:id" element={<OrderDetails />} />
      <Route path="/order/public/:orgId/:orderId" element={<PublicOrder />} />
      <Route path="/orders" element={<Orders />} />
    </Routes>
  );
};

export default Authorized;
