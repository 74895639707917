import { useState } from 'react';

import {
  Container,
  Row,
} from 'react-bootstrap';

import ImageModal from '../Modals/ImageModal';
import Gcard from './Gcard';

const DisplayGallery = ({ products }) => {
  const [showImage, setShowImage] = useState(false);

  if (!products) return null;
  if (products && products.length === 0)
    return <Container>No Products</Container>;

  return (
    <Container>
      <Row>
        {products.map((product) => (
          <Gcard
            key={product.id}
            product={product}
            setShowImage={setShowImage}
          />
        ))}
      </Row>
      <ImageModal showImage={showImage} setShowImage={setShowImage} />
    </Container>
  );
};

export default DisplayGallery;
