import React from 'react';

import { useSnackbar } from 'notistack';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';

import { inviteStaff } from '../../store/actions/staffActions';

export const InviteStaff = ({ show, setShow }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState("VI");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = () => {
    const validated = validate();
    if (!validated) return;
    dispatch(inviteStaff(email, selectedValue))
      .then((res) => {
        enqueueSnackbar("Invite staff success", { variant: "success" });
        setEmail(null);
        setSelectedValue("VI");
        setShow(false);
      })
      .catch((err) => {
        Object.values(err.response.data).forEach((val) => {
          enqueueSnackbar(val, { variant: "error" });
        });
      });
  };

  const validate = () => {
    if (email === null) {
      enqueueSnackbar("Please enter email", { variant: "error" });
      return false;
    }
    if (selectedValue === null) {
      enqueueSnackbar("Please select role", { variant: "error" });
      return false;
    }
    return true;
  };

  const handleClose = () => {
    setShow(false);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "Role",
    inputProps: { "aria-label": item },
  });

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Kutsu uusi käyttäjä henkilöstöön</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="validationEmail">
              <Form.Label>Sähköpostiosoite</Form.Label>
              <Form.Control
                type="email"
                name="email"
                required
                onChange={(event) => setEmail(event.target.value)}
              />
            </Form.Group>
          </Row>
          <Divider />
          <Row
            className="my-2 cursor-pointer"
            onClick={() => setSelectedValue("VI")}
          >
            <Col xs="auto">
              <Radio {...controlProps("VI")} />
            </Col>
            <Col>
              <Typography gutterBottom variant="h6" component="div">
                Katselija
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Oikeus katsoa rajattuja sisältöä
              </Typography>
            </Col>
          </Row>
          <Divider />
          <Row
            className="my-2 cursor-pointer"
            onClick={() => setSelectedValue("CL")}
          >
            <Col xs="auto">
              <Radio {...controlProps("CL")} />
            </Col>
            <Col>
              <Typography gutterBottom variant="h6" component="div">
                Peruskäyttäjä
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Voi vaan katsoa sisältöä ja tilata teoksia
              </Typography>
            </Col>
          </Row>
          <Divider />
          <Row
            className="my-2 cursor-pointer"
            onClick={() => setSelectedValue("ET")}
          >
            <Col xs="auto">
              <Radio {...controlProps("ET")} />
            </Col>
            <Col>
              <Typography gutterBottom variant="h6" component="div">
                Muokkaaja
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Voi muokata sisältöä ja tilata teoksia
              </Typography>
            </Col>
          </Row>
          <Divider />
          <Row
            className="my-2 cursor-pointer"
            onClick={() => setSelectedValue("OW")}
          >
            <Col xs="auto">
              <Radio {...controlProps("OW")} />
            </Col>
            <Col>
              <Typography gutterBottom variant="h6" component="div">
                Pääkäyttäjä
              </Typography>
              <Typography variant="body2" color="text.secondary">
                oikeudet kaikkiin toimintoihin
              </Typography>
            </Col>
          </Row>
          <Divider />

          <Row
            className="my-2 cursor-pointer"
            onClick={() => setSelectedValue("IE")}
          >
            <Col xs="auto">
              <Radio {...controlProps("IE")} />
            </Col>
            <Col>
              <Typography gutterBottom variant="h6" component="div">
                Inventoija
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Oikeus muokata teoksien sisältöä ja liiteitä
              </Typography>
            </Col>
          </Row>
          <Divider />

          <Row className="mt-3">
            <Col className="d-flex justify-content-end">
              <Button variant="danger" onClick={handleClose} className="mx-3">
                Peruuta
              </Button>
              <Button
                variant="primary"
                onClick={(event) => handleSubmit(event)}
              >
                Tallenna
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
