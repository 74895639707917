import { useState } from 'react';

import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Button,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import {
  Paper,
  Typography,
} from '@mui/material';

import Layout from '../../components/Layouts/Layout';
import { addProduct } from '../../store/actions/productActions';

const validationSchema = Yup.object().shape({
  art_id: Yup.string()
    .max(50, "Liian pitkä")
    .required("Tämä kenttä on pakollinen"),
  title: Yup.string()
    .max(50, "Liian pitkä")
    .required("Tämä kenttä on pakollinen"),
  year: Yup.string()
    .max(50, "Liian pitkä"),
  artist: Yup.string()
    .max(50, "Liian pitkä")
    .required("Tämä kenttä on pakollinen"),

  mainclass: Yup.string()
    .max(50, "Liian pitkä")
    .required("Tämä kenttä on pakollinen"),
  technique: Yup.string()
    .max(50, "Liian pitkä")
    .required("Tämä kenttä on pakollinen"),
  value: Yup.number().required("Tämä kenttä on pakollinen"),
  height: Yup.number().required("Tämä kenttä on pakollinen"),
  width: Yup.number().required("Tämä kenttä on pakollinen"),
  depth: Yup.number().required("Tämä kenttä on pakollinen"),
});

const AddProduct = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (values) => {
    setIsSubmitting(true);
    values.document = file;

    dispatch(addProduct(values))
      .then((res) => {
        setIsSubmitting(false);
        navigate("/Product/EditProduct/" + res.data.id);
      })
      .catch(() => {
        enqueueSnackbar(`Lisäys epäonnistui`, {
          variant: "error",
        });
        setIsSubmitting(false);
      });
  };

  const addFile = (event) => {
    setFile(event.currentTarget.files[0]);
  };

  return (
    <Layout>
      <Container>
        <Row className="justify-content-center">
          <Col md="9" lg="6">
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h4" component="h1">
                Lisää Teos
              </Typography>

              <Formik
                enableReinitialize
                initialValues={{
                  art_id: "",
                  title: "",
                  year: "",
                  mainclass: "",
                  technique: "",
                  artist: "",
                  value: "",
                  document: "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                  handleSubmit(values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="validationArtID">
                        <Form.Label>TeosID</Form.Label>
                        <Form.Control
                          type="text"
                          name="art_id"
                          value={values.art_id}
                          onChange={handleChange}
                          isInvalid={!!errors.art_id}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.art_id}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="validationArtist">
                        <Form.Label>Taiteilija</Form.Label>
                        <Form.Control
                          type="text"
                          name="artist"
                          value={values.artist}
                          onChange={handleChange}
                          isInvalid={!!errors.artist}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.artist}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="validationTitle">
                        <Form.Label>Teoksen nimi</Form.Label>
                        <Form.Control
                          type="text"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          isInvalid={!!errors.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.title}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="validationYear">
                        <Form.Label>Ajoitus</Form.Label>
                        <Form.Control
                          type="text"
                          name="year"
                          value={values.year}
                          onChange={handleChange}
                          isInvalid={!!errors.year}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.year}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="validationTechnique">
                        <Form.Label>Tekniikka</Form.Label>
                        <Form.Control
                          type="text"
                          name="technique"
                          value={values.technique}
                          onChange={handleChange}
                          isInvalid={!!errors.technique}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.technique}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="validationMainClass">
                        <Form.Label>Pääluokka</Form.Label>
                        <Form.Select
                          name="mainclass"
                          value={values.mainclass}
                          onChange={handleChange}
                          isInvalid={!!errors.mainclass}
                        >
                          <option value="">Valitse...</option>
                          <option value="maalaus">Maalaus</option>
                          <option value="piirustus">Piirustus</option>
                          <option value="grafiikka">Grafiikka</option>
                          <option value="veistos">Veistos</option>
                          <option value="valokuva">Valokuva</option>
                          <option value="esineteos">Esineteos</option>
                          <option value="installaatio">Installaatio</option>
                          <option value="mediataide">Mediataide</option>
                          <option value="ympäristötaide">Ympäristötaide</option>
                          <option value="biotaide">Biotaide</option>
                          <option value="taideteollisuusesine">Taideteollisuusesine</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.mainclass}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="validationValue">
                        <Form.Label>Vakuutusarvo</Form.Label>
                        <Form.Control
                          type="number"
                          step=".01"
                          name="value"
                          value={values.value}
                          onChange={handleChange}
                          isInvalid={!!errors.value}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.value}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        component="div"
                      >
                        Teoksen mitat
                      </Typography>

                      <Form.Group as={Col} controlId="validationHeight">
                        <Form.Label>Korkeus</Form.Label>
                        <Form.Control
                          type="number"
                          step=".01"
                          name="height"
                          value={values.height}
                          onChange={handleChange}
                          isInvalid={!!errors.height}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.height}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} controlId="validationWidth">
                        <Form.Label>Leveys</Form.Label>
                        <Form.Control
                          type="number"
                          step=".01"
                          name="width"
                          value={values.width}
                          onChange={handleChange}
                          isInvalid={!!errors.width}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.width}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} controlId="validationDepth">
                        <Form.Label>Syvyys</Form.Label>
                        <Form.Control
                          type="number"
                          step=".01"
                          name="depth"
                          value={values.depth}
                          onChange={handleChange}
                          isInvalid={!!errors.depth}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.depth}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="validationDocument">
                        <Form.Label>Teoksen kuva</Form.Label>
                        <br />
                        <small>Maxinum size 1mb</small>
                        <br />
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          name="document"
                          max-size="1"
                          type="file"
                          onChange={addFile}
                        />
                      </Form.Group>
                    </Row>

                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="beweship"
                    >
                      {isSubmitting ? "Tallennetaan..." : "Tallenna"}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export default AddProduct;
