function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
}

export function stringAvatar(name) {
  let firstLetter;
  let secondLetter;
  const splitName = name.split(" ");
  if (splitName.length > 1) {
    firstLetter = splitName[0].charAt(0);
    secondLetter = splitName[1].charAt(0);
  } else {
    firstLetter = splitName[0].charAt(0);
    secondLetter = "";
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${firstLetter}${secondLetter}`,
  };
}
