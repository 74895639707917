const modalReducer = (state = null, action) => {
  switch (action.type) {
    case "SHOW_IMAGE":
      return action.payload;
    case "CLOSE_MODAL":
      return action.payload;
    default:
      return state;
  }
};

export default modalReducer;
