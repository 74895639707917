import React from "react";
import { Routes, Route } from "react-router-dom";

import SignUp from "../pages/Account/Register";
import Login from "../pages/Account/Login";
import ForgotPassword from "../pages/Account/ForgotPassword";

import PublicOrder from "../pages/Order/PublicOrder";
import ResetPassword from '../pages/Account/ResetPassword';

const Unauthorized = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/Account/Login/" element={<Login />} />
      <Route path="/Account/ForgotPassword/" element={<ForgotPassword />} />
      <Route path="/password-reset/*" element={<ResetPassword />} />
      <Route path="/invitations/accept-invite/*" element={<SignUp />} />
      <Route path="/order/public/:orgId/:orderId" element={<PublicOrder />} />
    </Routes>
  );
};

export default Unauthorized;
