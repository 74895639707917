import orderServices from "../services/orderServices";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const getOrders = () => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");

  return async (dispatch) => {
    await orderServices.getOrders(orgID, token).then((response) => {
      dispatch({
        type: "GET_ORDERS",
        payload: response.data,
      });
    });
  };
};

export const getOrder = (id) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");
  return async (dispatch) => {
    await orderServices.getOrder(id, orgID, token).then((response) => {
      dispatch({
        type: "GET_ORDER",
        payload: response.data,
      });
    });
  };
};

export const getPublicOrder = (id, orgID) => {
  return async (dispatch) => {
    await orderServices.getPublicOrder(id, orgID).then((response) => {
      dispatch({
        type: "GET_ORDER",
        payload: response.data,
      });
    });
  };
};

export const changeOrder = (id, data) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");
  return async (dispatch) => {
    await orderServices.changeOrder(id, data, orgID, token);
    await orderServices.getOrder(id, orgID, token).then((response) => {
      dispatch({
        type: "GET_ORDER",
        payload: response.data,
      });
    });
  };
};

export const deleteOrder = (id) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");
  return async (dispatch) => {
    await orderServices.deleteOrder(id, orgID, token);
    await orderServices.getOrders(orgID, token).then((response) => {
      dispatch({
        type: "GET_ORDERS",
        payload: response.data,
      });
    });
  };
};
