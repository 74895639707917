const localCart = JSON.parse(localStorage.getItem("cart")) || [];

const cartReducer = (state = localCart, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      return {
        ...state,
        arts: action.payload,
      };
    case "REMOVE_ITEM":
      return {
        ...state,
        arts: action.payload,
      };
    case "ORDER":
      return {
        ...state,
        arts: action.payload,
        draft: null,
      };
    case "SELECTORGANIZATION":
      return [];
    case "LOGOUT":
      return [];
    case "DRAFT":
      return { ...state, draft: action.payload };
    default:
      return state;
  }
};

export default cartReducer;
