import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import authReducers from "./reducers/authReducer";
import productsReducer from "./reducers/productsReducer";
import productReducer from "./reducers/productReducer";
import organizationReducer from "./reducers/organizationReducer";
import modalReducer from "./reducers/modalReducer";
import historyReducer from "./reducers/historyReducer";
import staffReducer from "./reducers/staffReducer";
import cartReducer from "./reducers/cartReducer";
import reportReducer from "./reducers/reportReducer";
import ordersReducer from "./reducers/ordersReducer";
import othersReducer from './reducers/othersReducer';

const reducer = combineReducers({
  auth: authReducers,
  products: productsReducer,
  product: productReducer,
  organizations: organizationReducer,
  modal: modalReducer,
  history: historyReducer,
  staff: staffReducer,
  cart: cartReducer,
  reports: reportReducer,
  orders: ordersReducer,
  others: othersReducer,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
