import axios from "axios";
const { REACT_APP_BACKEND_URL } = process.env;
const baseUrl = `${REACT_APP_BACKEND_URL}`;

const postStaff = (data, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.post(`${baseUrl}/org/${orgID}/invitation/`, data, config);
};

const sendInvitation = (id, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.post(
    `${baseUrl}/org/${orgID}/invitation/${id}/send/`,
    null,
    config
  );
};

const deleteStaff = (id, orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(`${baseUrl}/org/${orgID}/invitation/${id}/`, config);
};

const getStaff = (orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.get(`${baseUrl}/org/${orgID}/user/`, config);
};

const getInvitedStaff = (orgID, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.get(`${baseUrl}/org/${orgID}/invitation/`, config);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getStaff,
  getInvitedStaff,
  postStaff,
  deleteStaff,
  sendInvitation,
};
