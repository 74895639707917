import React from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { AddCondition } from "../../../Modals/AddCondition";
import { useSelector } from "react-redux";
import { convertDate, getFullName } from "../../../../utils/HelperFunction";
import { conditionEnum } from "../../../../utils/Constant";

const Condition = () => {
  // state for showing model
  const [show, setShow] = React.useState(false);
  const conditions = useSelector((state) => state.product.conditions);

  conditions.sort((a, b) => {
    return new Date(b.created) - new Date(a.created);
  });

  return (
    <>
      <Row>
        <Col>
          <Typography variant="h4" component="h1">
            Kunto
          </Typography>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button variant="beweship" onClick={() => setShow(true)}>
            Lisää
          </Button>
        </Col>
      </Row>

      <Timeline position="right">
        {conditions.map((condition) => (
          <TimelineItem key={condition.id}>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              <Typography>{convertDate(condition.created)}</Typography>
              <Typography>{getFullName(condition.person)}</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography variant="h6" component="span">
                {conditionEnum[condition.condition_class]}
              </Typography>
              <Typography>{condition.condition}</Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <AddCondition show={show} handleClose={() => setShow(false)} />
    </>
  );
};
export default Condition;
