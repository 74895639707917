import { useState } from 'react';

import {
  Button,
  Container,
  Nav,
  Navbar,
} from 'react-bootstrap';
import { AiFillHome } from 'react-icons/ai';
import {
  FaExchangeAlt,
  FaList,
  FaUsers,
} from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { GrOrganization } from 'react-icons/gr';
import { HiDocumentReport } from 'react-icons/hi';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Link,
  useNavigate,
} from 'react-router-dom';

import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import logo from '../../assets/beweshiplogo.png';
import {
  selectOrganization,
  signOut,
} from '../../store/actions/authActions';
import {
  ViewLogPermission,
  ViewOrdersPermission,
  ViewReportPermission,
  ViewStaffPermission,
} from '../../utils/FeaturePermission';
import ShoppingCard from './ShoppingCard';

const Navigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const currentOrganization = useSelector(
    (state) => state.auth.currentOrganization
  );

  const organizations = useSelector((state) => state.organizations);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleSignOut = () => {
    dispatch(signOut());
    navigate("/");
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSelectOrganization = (organizationId) => {
    const organization = organizations.find((org) => org.id === organizationId);
    if (organization) {
      dispatch(selectOrganization(organization));
    }
    navigate("/");
  };

  return (
    <Container>
      <Nav.Link as={Link} to="/" className="p-0">
        <CardMedia
          component="img"
          height="80"
          sx={{ objectFit: "contain", width: "unset" }}
          image={logo}
          alt="Beweship Logo"
        />
      </Nav.Link>

      <Navbar expand="sm">
        <Navbar.Toggle />
        <Navbar.Collapse>
          {currentOrganization && (
            <Nav variant="pills" className="me-auto">
              <Nav.Link as={Link} to="/">
                <AiFillHome className="mr-2" /> Koti
              </Nav.Link>

              {ViewLogPermission(currentOrganization.user_type) && (
                <Nav.Link as={Link} to="/Logs">
                  <FaExchangeAlt className="mr-2" /> Loki
                </Nav.Link>
              )}
              {ViewReportPermission(currentOrganization.user_type) && (
                <Nav.Link as={Link} to="/Reports">
                  <HiDocumentReport className="mr-2" /> Raportit
                </Nav.Link>
              )}
              {ViewOrdersPermission(currentOrganization.user_type) && (
                <Nav.Link as={Link} to="/Orders">
                  <FaList className="mr-2" /> Tilaukset
                </Nav.Link>
              )}
              {ViewStaffPermission(currentOrganization.user_type) && (
                <Nav.Link as={Link} to="/Staffs">
                  <FaUsers className="mr-2" /> Henkilöstö
                </Nav.Link>
              )}
            </Nav>
          )}

          {user && (
            <>
              <ShoppingCard />
              <Box sx={{ flexGrow: 0, marginRight: 5 }}>
                <Tooltip title="Avaa asetukset">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <GrOrganization />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Typography variant="h6" className="mx-3">
                    Vaihda organisaatiosi
                  </Typography>
                  
                  {organizations && organizations.length > 0 && organizations.map((organization) => (
                    <MenuItem
                      key={organization.id}
                      onClick={() => handleSelectOrganization(organization.id)}
                    >
                      {organization.name}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Button
                variant="outline-danger"
                className="centered-label"
                onClick={handleSignOut}
              >
                <FiLogOut className="mr-2" /> Kirjaudu Ulos
              </Button>
            </>
          )}
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};

export default Navigation;
