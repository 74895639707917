import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, getUserOrganizations } from "./store/actions/authActions";
import Unauthorized from "./routes/Unauthorized";
import Authorized from "./routes/Authorized";
import Cookies from "universal-cookie";
import { SelectOrganization } from "./components/Modals/SelectOrganization";

function App() {
  const dispatch = useDispatch();

  const cookies = new Cookies();
  const token = cookies.get("jid");

  useEffect(() => {
    if (token) {
      dispatch(getUserInfo());
      dispatch(getUserOrganizations());
    }
  }, [dispatch, token]);

  const user = useSelector((state) => state.auth?.user);
  const organizations = useSelector((state) => state.organizations);
  const currentOrganization = useSelector(
    (state) => state.auth?.currentOrganization
  );
  if (user && user.email && currentOrganization) {
    return <Authorized />;
  } else if (user && user.email && organizations) {
    return <SelectOrganization />;
  } else {
    return <Unauthorized />;
  }
}

export default App;
