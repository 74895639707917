import React from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { AddLocation } from "../../../Modals/AddLocation";
import { useSelector } from "react-redux";
import { getFullName } from "../../../../utils/HelperFunction";

const Location = () => {
  // state for showing model
  const [show, setShow] = React.useState(false);
  const locations = useSelector((state) => state.product.locations);

  // convert 2021-11-25T19:32:43.946684Z to 25/11/2021 19:32:43
  const convertDate = (date) => {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const hours = newDate.getHours();
    const minutes = newDate.getMinutes();
    const seconds = newDate.getSeconds();
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  // sort locations by date time
  const sortedLocations = locations.sort((a, b) => {
    return new Date(b.created) - new Date(a.created);
  });

  return (
    <>
      <Row>
        <Col>
          <Typography variant="h4" component="h1">
            Sijainti
          </Typography>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button variant="beweship" onClick={() => setShow(true)}>
            Lisää
          </Button>
        </Col>
      </Row>

      <Timeline position="right">
        {sortedLocations.map((location) => (
          <TimelineItem key={location.id}>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              <Typography>{convertDate(location.created)}</Typography>
              <Typography>{getFullName(location.person)}</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography variant="h6" component="span">
                {location.location}
              </Typography>
              <Typography>{location.location_detail}</Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <AddLocation show={show} handleClose={() => setShow(false)} />
    </>
  );
};
export default Location;
