import { useSnackbar } from 'notistack';
import { Col } from 'react-bootstrap';
import {
  MdAddShoppingCart,
  MdEdit,
} from 'react-icons/md';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Link } from 'react-router-dom';

import {
  CardActionArea,
  CardMedia,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';

import noImage from '../../assets/no-image.svg';
import { addItemToCart } from '../../store/actions/cartAction';
import { showImage } from '../../store/actions/modalActions';
import {
  conditionEnum,
  conservationEnum,
} from '../../utils/Constant';
import {
  DeletePermission,
  EditPermission,
  OrderPermission,
} from '../../utils/FeaturePermission';
import ConfirmDeleteArt from '../mui/ConfirmDeleteArt';

export default function ProductRow({ product }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const img = product.documents[0]?.image?.replace(/["]+/g, "");
  const auth = useSelector((state) => state.auth);

  const handleAddItem = () => {
    dispatch(addItemToCart(product))
      .then(() => {
        enqueueSnackbar("Tuote lisätty kohteeseen", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Tuote on jo kohdessa", {
          variant: "error",
        });
      });
  };

  const handleShowImage = (title) => {
    const image = img ? img : noImage;
    dispatch(showImage(image, title));
  };

  const currentLocation = product.locations.sort((a, b) =>
    a.id < b.id ? 1 : -1
  )[0];
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <div className="unset-img">
          <CardActionArea onClick={() => handleShowImage(product.title)}>
            <CardMedia
              component="img"
              alt={`${product.title} image`}
              image={img ? img : noImage}
              className="custom-img"
            />
          </CardActionArea>
        </div>
      </TableCell>
      <TableCell>
        <p>{product.art_id}</p>
        <p>
          <small>
            {product.invnumber1}
            {product.invnumber2 && `, ${product.invnumber2}`}
          </small>
        </p>
      </TableCell>
      <TableCell>
        <p><b>{product.artist}</b></p>
        <p><b>{product.title}</b></p>
      </TableCell>
      <TableCell>
        {/* if product have size */}
        {product.height && (
          <>
            <p>
              <b>koko: </b>
              {/* if product size have depth '?' if true, ':' if false */}
              {product.depth
                ? `${product.height} x ${product.width} x ${product.depth} cm`
                : `${product.height} x ${product.width} cm`}
            </p>
          </>
        )}
        <p>{product.mainclass}</p>
      </TableCell>
      <TableCell>
        <p>
          <b>Kunto: </b>
          {product?.conditions &&
            conditionEnum[product.conditions[0]?.condition_class]}
        </p>
        <p>
          <b>Konser: </b>
          {product?.conservations &&
            conservationEnum[product.conservations[0]?.conservation_class]}
        </p>
      </TableCell>
      <TableCell>
        <p>{currentLocation?.location}</p>
        <p>{currentLocation?.location_detail}</p>
      </TableCell>
      <TableCell>
        <Col className="d-flex flex-column">
          {EditPermission(auth.currentOrganization.user_type) && (
            <Tooltip title="Muokka tietoja">
              <IconButton
                aria-label="edit"
                component={Link}
                to={`/Product/EditProduct/${product.id}`}
                target="_blank"
              >
                <MdEdit />
              </IconButton>
            </Tooltip>
          )}
          {DeletePermission(auth.currentOrganization.user_type) && (
            <ConfirmDeleteArt artID={product.id} />
          )}
          {OrderPermission(auth.currentOrganization.user_type) && (
            <Tooltip title="Lisää kohteeseen">
              <IconButton aria-label="Lisää kohteeseen" onClick={handleAddItem}>
                <MdAddShoppingCart />
              </IconButton>
            </Tooltip>
          )}
        </Col>
      </TableCell>
    </TableRow>
  );
}
