import * as React from "react";
import Popover from "@mui/material/Popover";
import { Button } from "react-bootstrap";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import {
  getReport,
  downloadReportAll,
  downloadReportCondition,
  downloadReportAccounting,
  downloadReportWorks,
} from "../../store/actions/reportActions";

export default function BasicPopover({ reportID }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const downloadPDF = (id) => {
    getReport(id);
  };

  const downloadAll = (id) => {
    downloadReportAll(id);
  };

  const downloadCondition = (id) => {
    downloadReportCondition(id);
  };

  const downloadAccounting = (id) => {
    downloadReportAccounting(id);
  };

  const downloadWorks = (id) => {
    downloadReportWorks(id);
  };

  return (
    <>
      <Button
        aria-describedby={id}
        variant="primary"
        className="mx-2"
        onClick={handleClick}
      >
        Lataa
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => downloadPDF(reportID)}>
              <ListItemText primary="PDF" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => downloadAll(reportID)}>
              <ListItemText primary="Excel, Kaikki tiedot" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => downloadCondition(reportID)}>
              <ListItemText primary="Excel, Kuntotiedot" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => downloadAccounting(reportID)}>
              <ListItemText primary="Excel, Kirjanpitoaineisto" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => downloadWorks(reportID)}>
              <ListItemText primary="Excel, Artworks Report" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
