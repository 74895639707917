export const showImage = (image, title) => {
  return async (dispatch) => {
    const payload = {
      title,
      image,
      showImage: true,
    };
    dispatch({
      type: "SHOW_IMAGE",
      payload,
    });
  };
};

export const closeModal = () => {
  return async (dispatch) => {
    const payload = {
      showImage: false,
      image: null,
    };
    dispatch({
      type: "CLOSE_MODAL",
      payload,
    });
  };
};
