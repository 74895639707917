import Cookies from "universal-cookie";
import reportService from "../services/reportService";
var fileDownload = require("js-file-download");

const cookies = new Cookies();

export const downloadReport = (data) => {
  return async (dispatch) => {
    const token = cookies.get("jid");
    const orgID = cookies.get("currentorg");
    const postData = {
      art: data.arts,
      title: data.title,
      footer: data.footer,
    };

    const report = await reportService.postReport(postData, orgID, token);
    const reportid = report.data.id;
    if (data.logo) {
      await reportService.patchReport(reportid, data.logo, orgID, token);
    }
    const download = await reportService.downloadReport(reportid, orgID, token);
    const datas = download.data;
    var file = new Blob([datas], { type: "application/pdf" });
    fileDownload(file, "report.pdf");

    dispatch({
      type: "DOWNLOAD_REPORT",
      payload: download.data,
    });
  };
};

export const getReport = async (reportid) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");

  const download = await reportService.downloadReport(reportid, orgID, token);
  const datas = download.data;
  var file = new Blob([datas], { type: "application/pdf" });
  var fileURL = URL.createObjectURL(file);
  window.open(fileURL);

  return {
    type: "DOWNLOAD_REPORT",
  };
};

export const downloadReportAll = async (reportid) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");

  const download = await reportService.downloadReportAll(
    reportid,
    orgID,
    token
  );
  const datas = download.data;
  var file = new Blob([datas], {
    type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  fileDownload(file, "Kaikki tiedot.xlsx");

  return {
    type: "DOWNLOAD_REPORT",
  };
};

export const downloadReportCondition = async (reportid) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");
  const download = await reportService.downloadReportCondition(
    reportid,
    orgID,
    token
  );
  const datas = download.data;
  var file = new Blob([datas], {
    type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  fileDownload(file, "Kuntotiedot.xlsx");
  return {
    type: "DOWNLOAD_REPORT",
  };
};
export const downloadReportWorks = async (reportid) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");
  const download = await reportService.downloadReportWorks(
    reportid,
    orgID,
    token
  );

  const datas = download.data;
  var file = new Blob([datas], {
    type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  fileDownload(file, "Artworks Report.xlsx");
  return {
    type: "DOWNLOAD_REPORT",
  };
};
export const downloadReportAccounting = async (reportid) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");
  const download = await reportService.downloadReportAccounting(
    reportid,
    orgID,
    token
  );
  const datas = download.data;
  var file = new Blob([datas], {
    type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  fileDownload(file, "Kirjanpitoaineisto.xlsx");

  return {
    type: "DOWNLOAD_REPORT",
  };
};

export const getReports = () => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");

  return async (dispatch) => {
    const reports = await reportService.getReports(orgID, token);
    dispatch({
      type: "GET_REPORTS",
      payload: reports.data,
    });
  };
};

export const deleteReport = (id) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");

  return async (dispatch) => {
    await reportService.deleteReport(id, orgID, token).then((response) => {
      dispatch({
        type: "DELETE_REPORT",
        payload: id,
      });
    });
  };
};
