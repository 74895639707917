import React from "react";
import { Col, Row } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { stringAvatar } from "../../utils/stringAvatar";
import { MdOutlineNavigateNext } from "react-icons/md";
import { roleEnum } from "../../utils/Constant";
import { getFullName } from "../../utils/HelperFunction";

const ActiveUserTable = ({ rows }) => {
  const handleClick = (event, id) => {
    console.log(id);
  };

  return (
    <TableContainer>
      <Table aria-label="active table">
        <TableHead>
          <TableRow>
            <TableCell colSpan="2">USERS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              hover={true}
              onClick={(event) => handleClick(event, row.id)}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Row>
                  <Col className="d-flex align-items-center" xs="auto">
                    <Avatar {...stringAvatar(getFullName(row.user))} />
                  </Col>

                  <Col>
                    <Typography gutterBottom variant="body2" component="div">
                      {getFullName(row.user)}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {row.user.email}
                    </Typography>
                  </Col>
                </Row>
              </TableCell>
              <TableCell align="right">
                <Chip
                  sx={{ fontSize: 14, fontWeight: 500 }}
                  label={roleEnum[row.user_type]}
                  color="primary"
                />
                <MdOutlineNavigateNext size="40" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ActiveUserTable;
