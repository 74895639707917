import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../../assets/beweshiplogo.png';
import { useDispatch } from 'react-redux';
import { signIn } from '../../store/actions/authActions';
import { useSnackbar } from 'notistack';

const theme = createTheme();

export default function Login() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const credentials = {
      email: data.get('email').toLocaleLowerCase(),
      password: data.get('password'),
    };
    dispatch(signIn(credentials)).catch((err) => {
      const message = err.message;
      const status = err.response?.status;
      if (message === 'Network Error') {
        enqueueSnackbar('Palvelimeen ei saatu yhteyttä', { variant: 'error' });
      } else if (status === 500) {
        enqueueSnackbar('Palvelimessa tapahtui virhe', { variant: 'error' });
      } else if (status === 400) {
        enqueueSnackbar(
          'Jokin on hassusti. Tarkista sähköpostiosoite ja salasana.',
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar('Virhe kirjautumisessa. Yritä myöhemmin uudelleen.', {
          variant: 'error',
        });
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CardMedia component="img" image={logo} alt="Beweship Logo" />
          <Typography component="h1" variant="h5" sx={{ marginTop: 5 }}>
            Kirjaudu sisään
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Sähköposti"
              name="email"
              autoComplete="email"
              variant="filled"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Salasana"
              type="password"
              id="password"
              variant="filled"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Kirjaudu sisään
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/Account/ForgotPassword/" variant="body2">
                  Unohtuiko salasana?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
