import React, { useEffect } from 'react';

import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Cookies from 'universal-cookie';

import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { selectOrganization } from '../../store/actions/authActions';
import { roleEnum } from '../../utils/Constant';
import Layout from '../Layouts/Layout';

export const SelectOrganization = () => {
  const organizations = useSelector((state) => state.organizations);

  const dispatch = useDispatch();
  const cookies = new Cookies();
  const currentOrganization = cookies.get("currentorg");

  const handleSelectOrganization = (organizationId) => {
    const organization = organizations.find((org) => org.id === organizationId);
    if (organization) {
      dispatch(selectOrganization(organization));
    }
  };

  useEffect(() => {
    if (currentOrganization) {
      handleSelectOrganization(parseInt(currentOrganization));
    }
  }, [currentOrganization]);

  return (
    <Layout>
      <Container className="mt-5">
        <Row className="mb-3">
          <h1>Valitse organisaatio:</h1>
        </Row>
        {organizations.length > 0 ? (
          organizations.map((organization) => (
            <div key={organization.id}>
              <Row className="mb-3">
                <Col>
                  <Card>
                    <CardActionArea
                      onClick={() => handleSelectOrganization(organization.id)}
                    >
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {organization.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {organization.description}
                        </Typography>

                        <Typography variant="body2" color="text.secondary">
                          {roleEnum[organization.user_type]}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Col>
              </Row>
            </div>
          ))
        ) : (
          <Row>
            <h4>
              Sinulle ei ole määritelty organisaatiota, ota yhteyttä
              palveluntarjoajaan
            </h4>
          </Row>
        )}
      </Container>
    </Layout>
  );
};
