import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../store/actions/modalActions";
import CardMedia from "@mui/material/CardMedia";

const ImageModal = () => {
  const modals = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  let showImage = false;
  const handleClose = () => {
    dispatch(closeModal());
  };

  if (modals && modals.showImage) {
    showImage = true;
  }
  const img = modals?.image;

  return (
    <Modal show={showImage} size="xl" onHide={() => handleClose(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{modals?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CardMedia component="img" sx={{ objectFit: "contain" }} image={img} />
      </Modal.Body>
    </Modal>
  );
};
export default ImageModal;
