import Navigation from "./Navigation";
import { useSelector } from "react-redux";
const Layout = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  return (
    <>
      {user && <Navigation />}
      {children}
      <div className="mt-5"></div>
    </>
  );
};

export default Layout;
