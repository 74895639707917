import { Row, Col, Form, Button } from "react-bootstrap";
import { FaThList, FaThLarge, FaPlus, FaQuestion } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import InstructionModal from "../Modals/InstructionModal";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CreatePermission } from "../../utils/FeaturePermission";

const ProductBoardMenu = (props) => {
  const { keyWord, handleSearchChange, handleDisplayGallery } = props;
  const [showInfo, setShowInfo] = useState(false);
  const auth = useSelector((state) => state.auth);

  return (
    <>
      <Row>
        <Col
          lg={{ span: 4 }}
          md={{ span: 5, order: "first" }}
          xs={{ span: 12, order: "last" }}
          className="my-2"
        >
          <Form.Control
            name="search"
            type="text"
            placeholder="Search..."
            value={keyWord}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </Col>
        <Col
          lg={{ offset: 4, span: 2 }}
          md={{ offset: 1, span: 3 }}
          xs={{ span: 6 }}
          className="my-2"
        >
          {CreatePermission(auth.currentOrganization.user_type) && (
            <Link to="/product/addproduct">
              <Button variant="beweship" className="centered-label">
                <FaPlus className="mr-2" />
                Lisää Teos
              </Button>
            </Link>
          )}
        </Col>
        <Col
          lg={{ span: 2 }}
          md={{ span: 3 }}
          xs={{ span: 6 }}
          className="d-flex justify-content-end my-2"
        >
          <Tooltip title="Näytä listana">
            <Button
              variant="outline-secondary"
              className="ml-2"
              onClick={() => {
                handleDisplayGallery(false);
              }}
            >
              <FaThList />
            </Button>
          </Tooltip>
          <Tooltip title="Näytä galleriana">
            <Button
              variant="outline-secondary"
              className="ml-2"
              onClick={() => {
                handleDisplayGallery(true);
              }}
            >
              <FaThLarge />
            </Button>
          </Tooltip>

          <Tooltip title="Ohjeet">
            <Button
              variant="outline-info"
              className="ml-2"
              onClick={() => {
                setShowInfo(true);
              }}
            >
              <FaQuestion />
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <InstructionModal showInfo={showInfo} setShowInfo={setShowInfo} />
    </>
  );
};

export default ProductBoardMenu;
