import * as React from 'react';

import { useSnackbar } from 'notistack';
import { Button } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import {
  MdDelete,
  MdDeleteForever,
} from 'react-icons/md';
import { useDispatch } from 'react-redux';

import { Button as MButton } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import {
  deleteConservation,
} from '../../../../../store/actions/productActions';

export default function BasicPopover(artId, conservationId) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDelete = () => {
    dispatch(deleteConservation(artId, conservationId)).then(() => {
      enqueueSnackbar(`Konservointitiedon poistaminen onnistui`, {
        variant: "success",
      });
    })
    .catch(() => {
      enqueueSnackbar(`Konservointitiedon poistaminen epäonnistui`, {
        variant: "error",
      });
    });

  };

  return (
    <>
      <Button
        aria-describedby={id}
        variant="outline-danger"
        className="mx-2"
        onClick={handleClick}
      >
        <MdDelete />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography sx={{ p: 2 }}>Olet poistamassa tämän</Typography>
        <MButton
          className="mx-3 mb-3"
          variant="outlined"
          endIcon={<IoMdClose />}
          onClick={handleClose}
        >
          Peruuta
        </MButton>
        <MButton
          className="mx-3 mb-3"
          variant="outlined"
          color="error"
          endIcon={<MdDeleteForever />}
          onClick={handleDelete}
        >
          Poista
        </MButton>
      </Popover>
    </>
  );
}
