import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@mui/material";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import Layout from "../../components/Layouts/Layout";
import { useDispatch } from "react-redux";
import { getOrders } from "../../store/actions/ordersActions";
import { useSelector } from "react-redux";
import { convertDate, getFullName } from "../../utils/HelperFunction";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Orders = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  const orders = useSelector((state) => state.orders);

  return (
    <Layout>
      <Container>
        <h1>Tilaukset</h1>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Tilauspvm, Tilausaika</TableCell>
                <TableCell>Mihin?</TableCell>
                <TableCell>Lisää tietoa</TableCell>
                <TableCell>Tilaaja</TableCell>
                <TableCell colSpan={2}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders &&
                orders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.id}</TableCell>
                    <TableCell>{convertDate(order.created)}</TableCell>
                    <TableCell>
                      {order.location}, {order.location_detail}
                    </TableCell>
                    <TableCell>{order.note},</TableCell>
                    <TableCell>{getFullName(order.ordered_by)}</TableCell>
                    <TableCell>
                      {order.draft
                        ? "Luonnos"
                        : order.accepted
                        ? "Hyväksytty"
                        : "Ei ole hyväksytty"}
                    </TableCell>
                    <TableCell>
                      <Link to={`/order/details/${order.id}`}>
                        <Button variant="primary">Lisää tietoa</Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Layout>
  );
};

export default Orders;
