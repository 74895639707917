import React from 'react';

import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  Box,
  Button,
  Grid,
  TextField,
} from '@mui/material/';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { resetPassword } from '../../store/actions/authActions';

const theme = createTheme();

const ResetPassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const newPassword1 = data.get('newPassword')
    const newPassword2 = data.get('confirmNewPassword')



    if (newPassword1 !== newPassword2) {
      enqueueSnackbar('Salasanat eivät täsmää', { variant: 'error' });
      return;
    }

    const uid = window.location.pathname.split('/')[3];
    const token = window.location.pathname.split('/')[4];
    
    const credentials = {
      uid: uid,
      token: token,
      new_password1: newPassword1,
      new_password2: newPassword2,
    }
    
    dispatch(resetPassword(credentials))
    .then(() => {
      enqueueSnackbar('Salasana nollattu onnistuneesti', { variant: 'success' });
      navigate('/');
    })
    .catch((err) => {
      if (err.response?.status === 400) {
        const errKeys = Object.keys(err.response.data);
        const errKey = errKeys[0];
        const errValue = err.response.data[errKey][0];
        enqueueSnackbar(`${errKey}: ${errValue}`, { variant: 'error' });
      } else if (err.response?.status === 500) {
        enqueueSnackbar('Palvelimessa tapahtui virhe', { variant: 'error' });
      } else {
        enqueueSnackbar('Yritä myöhemmin uudelleen.', { variant: 'error' });
      }
    });
};



      


    
    
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Nolla Salasana
          </Typography>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3, minWidth: 300 }}
          >
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="newPassword"
                  label="Salasana"
                  type="password"
                  id="newPassword"
                  variant="filled"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmNewPassword"
                  label="Vahvista Salasana"
                  type="password"
                  id="confirmNewPassword"
                  variant="filled"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Lähetä
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
export default ResetPassword;
