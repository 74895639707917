import Cookies from 'universal-cookie';

import productService from '../services/productServices';

const cookies = new Cookies();

export const getProducts = (id) => {
  const token = cookies.get("jid");
  const orgID = id || cookies.get("currentorg");
  return (dispatch) => {
    productService.getProducts(orgID, token).then((response) => {
      dispatch({
        type: "GET_PRODUCTS",
        payload: response.data.results,
      });

      dispatch({
        type: "GET_PRODUCTS_COUNT",
        payload: response.data.count,
      });
    });
  };
};

export const getProductsByPage = (id, page) => {
  const token = cookies.get("jid");
  const orgID = id || cookies.get("currentorg");
  return (dispatch) => {
    productService.getProductsByPage(orgID, page, token).then((response) => {
      dispatch({
        type: "GET_PRODUCTS",
        payload: response.data.results,
      });
    });
  };
};

export const getProduct = (id) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");
  return (dispatch) => {
    productService.getProduct(id, orgID, token).then((response) => {
      dispatch({
        type: "GET_PRODUCT",
        payload: response.data,
      });
    });
  };
};

export const addProduct = (values) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");

  const newProduct = {
    title: values.title,
    artist: values.artist,
    art_id: values.art_id,
    year: values.year,
    mainclass: values.mainclass,
    technique: values.technique,
    value: values.value,
    frame_height: values.frame_height,
    frame_width: values.frame_width,
    frame_depth: values.frame_depth,
  };

  //if have image
  let document;
  if (values.document) {
    document = values?.document;
  }

  return async (dispatch) => {
    // add product to datebase
    const addedProduct = await productService.postProduct(
      token,
      orgID,
      newProduct
    );

    // if have , add file to database
    if (document && addedProduct.data.id) {
      await productService.addDocument(
        document,
        addedProduct.data.id,
        orgID,
        token
      );
    }

    // refetch products from database
    const products = await productService.getProducts(orgID, token);

    // add product to store
    dispatch({
      type: "ADD_PRODUCT",
      payload: products.data,
    });

    // return added product
    return addedProduct;
  };
};

export const updateProduct = (product) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");

  return async (dispatch) => {
    await productService
      .patchProduct(product.id, product, orgID, token)
      .then((response) => {
        dispatch({
          type: "EDIT_PRODUCT",
          payload: response.data,
        });
      });
  };
};

export const addLocation = (id, location) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");

  return async (dispatch) => {
    await productService
      .postLocation(id, location, orgID, token)
      .then((response) => {
        dispatch({
          type: "ADD_LOCATION",
          payload: response.data,
        });
      });
  };
};

export const addCondition = (id, condition) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");

  return async (dispatch) => {
    await productService
      .postCondition(id, condition, orgID, token)
      .then((response) => {
        dispatch({
          type: "ADD_CONDITION",
          payload: response.data,
        });
      });
  };
};

export const addDocument = (id, document) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");

  return async (dispatch) => {
    await productService
      .addDocument(document.file,document.description, id, orgID, token)
      .then((response) => {
        dispatch({
          type: "ADD_DOCUMENT",
          payload: response.data,
        });
      });
  };
};

export const addConservation = (id, condition) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");

  return async (dispatch) => {
    await productService
      .postConservation(id, condition, orgID, token)
      .then((response) => {
        dispatch({
          type: "ADD_CONSERVATION",
          payload: response.data,
        });
      });
  };
};

export const editConservation = (artId, conserId, data) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");
  return async (dispatch) => {
    await productService
      .editConservation(artId, conserId, data, orgID, token)
      .then((response) => {
        dispatch({
          type: "EDIT_CONSERVATION",
          payload: response.data,
        });
      });
  };
}

export const deleteConservation = ({artId,conservationId}) => {
 
  const token = cookies.get("jid");
  const orgId = cookies.get("currentorg");

  return async (dispatch) => {
    await productService.deleteConservation(conservationId, artId, orgId, token).then((response) => {
      dispatch({
        type: "DELETE_CONSERVATION",
        payload: conservationId,
      });
    });
  };
};


export const deleteProduct = (id) => {
  const token = cookies.get("jid");
  const orgID = cookies.get("currentorg");

  return async (dispatch) => {
    await productService.deleteProduct(id, orgID, token).then((response) => {
      dispatch({
        type: "DELETE_PRODUCT",
        payload: id,
      });
    });
  };
};

export const addAttchment = (productId, file) => {
  const token = cookies.get("jid");
  return (dispatch) => {
    productService.addAttachment(productId, file, token).then((response) => {
      dispatch({
        type: "ADD_ATTACHMENT",
        payload: response.data,
      });
    });
  };
};
