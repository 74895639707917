const reportReducer = (state = null, action) => {
  switch (action.type) {
    case "GET_REPORTS":
      return action.payload;
    case "DELETE_REPORT":
      return state.filter((report) => report.id !== action.payload);
    default:
      return state;
  }
};

export default reportReducer;
