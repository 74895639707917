import EnhancedTable from "./EnhancedTable";
import ProductBoardMenu from "./ProductBoardMenu";
import { useState, useEffect } from "react";
import DisplayGallery from "./DisplayGallery";
import { useWindowWidth } from "@react-hook/window-size";
import { useSelector, useDispatch } from "react-redux";
import { getProducts, getProductsByPage } from "../../store/actions/productActions";
import ImageModal from "../Modals/ImageModal";
import { Pagination, Stack,CircularProgress } from '@mui/material';


const ProductBoard = (props) => {
  const [searchResult, setSearchResult] = useState([]);
  const [gallery, setGallery] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageAmount, setPageAmount] = useState(1);
  
  const [page, setPage] = useState(1);
  const handleChangePage = (event, value) => {
    setLoading(true)
    setPage(value);
    dispatch(getProductsByPage(currentOrganization.id, value));
    setLoading(false)
  };

  const scrWidth = useWindowWidth();
  const dispatch = useDispatch();
  //state for show image modal

  const [keyWord, setKeyWord] = useState("");

  const products = useSelector((state) => state.products);
  const productsCount = useSelector((state) => state.others.productsCount);
  const currentOrganization = useSelector(
    (state) => state.auth.currentOrganization
  );

  useEffect(() => {
    if(productsCount){
      setPageAmount(Math.ceil(productsCount/100))
    }
  }, [productsCount])

  useEffect(() => {
    if (currentOrganization) {
      setLoading(true)
      dispatch(getProducts(currentOrganization.id));
      setLoading(false)
    }
  }, [currentOrganization, dispatch]);

  useEffect(() => {
    setSearchResult(products);
  }, [products]);

  useEffect(() => {
    if (scrWidth < 980 && !gallery) {
      setGallery(true);
    }
    if (scrWidth > 980 && gallery) {
      setGallery(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrWidth]);

  const handleSearchChange = (searchTerm) => {
    setKeyWord(searchTerm);
    if (searchTerm !== "") {
      const filteredProductList = products.filter((product) => {
        return Object.values(product)
          .join(" ")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setSearchResult(filteredProductList);
    } else {
      setSearchResult(products);
    }
  };

  const handleDisplayGallery = (value) => {
    setGallery(value);
  };

  return (
    <>
      <ProductBoardMenu
        keyWord={keyWord}
        handleSearchChange={handleSearchChange}
        handleDisplayGallery={handleDisplayGallery}
      />
      <Stack spacing={2}>
      <Pagination count={pageAmount} page={page} onChange={handleChangePage} />

      {loading || !searchResult ? (
        <CircularProgress />
      ) : gallery ? (
        <DisplayGallery products={searchResult} />
      ) : (
        <EnhancedTable products={searchResult} />
      )}

      <ImageModal />
      <Pagination count={pageAmount} page={page} onChange={handleChangePage} />
      </Stack>
    </>
  );
};

export default ProductBoard;
