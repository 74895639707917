import React from 'react';

import { useSnackbar } from 'notistack';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { addDocument } from '../../store/actions/productActions';

export const AddAttachment = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = React.useState(null);
  const [description, setDescription] = React.useState('');

  const product = useSelector((state) => state.product);

  const handleSubmit = () => {
    const id = product.id;
    const data = {
      file,
      description,
    };
    if (file) {
      dispatch(addDocument(id, data))
        .then(() => {
          setFile(null);
          enqueueSnackbar("Lisäys onnistui", { variant: "success" });
          handleClose();
        })
        .catch((error) => enqueueSnackbar(`${error}`, { variant: "error" }));
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Lisää Liitteet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Row className="mb-3">
            <input
              id="contained-button-file"
              accept="image/*"
              name="document"
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </Row>
          <Form.Group controlId="formFileDescription" className="mb-3">
            <Form.Label>Kuvaus</Form.Label>
            <Form.Control 
              as="textarea" 
              rows={3} 
              placeholder="Anna lyhyt kuvaus liitteelle" 
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button variant="danger" onClick={handleClose} className="mx-3">
                Peruuta
              </Button>
              <Button
                variant="primary"
                onClick={(event) => handleSubmit(event)}
              >
                Tallenna
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
