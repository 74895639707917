import Cookies from "universal-cookie";
import orderServices from "../services/orderServices";

const cookies = new Cookies();

export const addItemToCart = (product) => {
  const localCart = JSON.parse(localStorage.getItem("cart")) || [];
  if (localCart.find((item) => item.id === product.id)) {
    return async (dispatch) => {
      await Promise.reject("Item already in cart");
      dispatch({
        type: "ADD_ITEM_FAIL",
        payload: null,
      });
    };
  } else {
    const newCart = [...localCart, product];
    localStorage.setItem("cart", JSON.stringify(newCart));
    return async (dispatch) => {
      dispatch({
        type: "ADD_ITEM",
        payload: newCart,
      });
    };
  }
};

export const removeItemFromCart = (productId) => {
  const localCart = JSON.parse(localStorage.getItem("cart")) || [];
  const newCart = localCart.filter((item) => item.id !== productId);
  localStorage.setItem("cart", JSON.stringify(newCart));

  return async (dispatch) => {
    dispatch({
      type: "REMOVE_ITEM",
      payload: newCart,
    });
  };
};

export const makeOrder = (details) => {
  return async (dispatch) => {
    const token = cookies.get("jid");
    const orgID = cookies.get("currentorg");
    if (details.orderId) {
      orderServices.changeOrder(details.orderId, details, orgID, token);
    } else {
      orderServices.postOrder(details, orgID, token);
    }
    dispatch({
      type: "ORDER",
      payload: null,
    });
  };
};

export const contineuDraft = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "DRAFT",
      payload: data,
    });
  };
};
