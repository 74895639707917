import React from 'react';

import { useSnackbar } from 'notistack';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { downloadReport } from '../../store/actions/reportActions';

export const CreateReport = ({ show, handleClose, arts }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = React.useState(null);
  const [footer, setFooter] = React.useState(null);
  const [file, setFile] = React.useState(null);

  const handleSubmit = () => {
    const data = {
      title,
      footer,
      logo: file,
      arts,
    };
    dispatch(downloadReport(data))
      .then(() => {
        enqueueSnackbar("Raportti luotu", { variant: "success" });
        handleClose();
      })
      .catch((error) => enqueueSnackbar(`${error}`, { variant: "error" }));
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Luo Raportti</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="validationTitle">
              <Form.Label>Otsikko</Form.Label>
              <Form.Control
                type="text"
                name="title"
                onChange={(event) => setTitle(event.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="validationFooter">
              <Form.Label>Alatunniste</Form.Label>
              <Form.Control
                type="text"
                name="footer"
                onChange={(event) => setFooter(event.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Label>Logo</Form.Label>
            <input
              id="contained-button-file"
              name="document"
              multiple
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button variant="danger" onClick={handleClose} className="mx-3">
                Peruuta
              </Button>
              <Button
                variant="primary"
                onClick={(event) => handleSubmit(event)}
              >
                Luo raportti
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
