const userReducer = (state = null, action) => {
  switch (action.type) {
    case "LOGIN":
      return action.payload;
    case "USERINFO":
      return action.payload;
    case "LOGOUT":
      return action.payload;
    case "CREATEUSER":
      return action.payload;
    case "RESETPASSWORD":
      return action.payload;
    case "SELECTORGANIZATION":
      return {
        ...state,
        currentOrganization: action.payload.currentOrganization,
      };
    default:
      return state;
  }
};

export default userReducer;
