import { Container } from "react-bootstrap";
import Layout from "../components/Layouts/Layout";

import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHead } from "@mui/material";
import TablePaginationActions from "../utils/TablePaginationActions";
import { getHistory } from "../store/actions/historyActions";
import { useDispatch, useSelector } from "react-redux";
import { convertDate, getFullName } from "../utils/HelperFunction";

export default function Logs() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHistory());
  }, [dispatch]);

  const history = useSelector((state) => state.history);
  if (history && history.length > 0) {
    history.sort((a, b) => {
      return new Date(b.requested_at) - new Date(a.requested_at);
    });
  }
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - history.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // convert string to json

  if (!history) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <Container>
        <TableContainer component={Paper} className="mt-3">
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    { label: "Kaikki", value: -1 },
                  ]}
                  colSpan={4}
                  count={history.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "Rivia per sivu:",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
              <TableRow>
                <TableCell>TeosID</TableCell>
                <TableCell>Muutos</TableCell>
                <TableCell align="right">Käyttäjä</TableCell>
                <TableCell align="right">Aika</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? history.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : history
              ).map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ width: 100 }}>id das</TableCell>
                  <TableCell>{row.change_message}</TableCell>

                  <TableCell style={{ width: 160 }} align="right">
                    {getFullName(row.user)}
                  </TableCell>
                  <TableCell style={{ width: 100 }} align="right">
                    {convertDate(row.action_time)}
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={2} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    { label: "Kaikki", value: -1 },
                  ]}
                  colSpan={4}
                  count={history?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "Rivia per sivu:",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Container>
    </Layout>
  );
}
